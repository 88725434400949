import { connect } from "react-redux";
const withForms = connect(state => ({
  forms:
    (state.admin &&
      state.admin.resources &&
      state.admin.resources &&
      state.admin.resources.forms &&
      state.admin.resources.forms.data) ||
    {}
}));
export default withForms;
