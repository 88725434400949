export default {
  VARCHAR: "varchar",
  CHAR: "char",
  INT: "int",
  TINYINT: "tinyint",
  BIGINT: "bigint",
  DATE: "date",
  DATETIME: "datetime",
  BOOL: "bool",
  DECIMAL: "decimal",
  DOUBLE: "double",
  TEXT: "text"
};
