import { List, Typography, createStyles, withStyles } from "@material-ui/core";
import { MenuItemLink, Responsive, getResources, translate } from "react-admin";
import React, { createElement, useState } from "react";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

import { DashboardIcon } from "./icons";
import FileSystemNavigator from "./components/FileSystemNavigator";
import SubMenu from "./SubMenu";
import SubMenuIcon from "@material-ui/icons/ChevronRight";
import { VERSION } from "./config";
import { WithPermissions } from "ra-core";
import classnames from "classnames";
import { compose } from "recompose";
import { connect } from "react-redux";
import customRoutes from "./routes";
import { withRouter } from "react-router-dom";

const groupOrders = {
  dashboard: 1,
  reporting: 2,
};

const styles = (theme) =>
  createStyles({
    opened: {
      width: 300,
      overflowY: "auto",
    },
    closed: {
      width: 58,
      overflow: "hidden",
      overflowY: "hidden",
    },
    root: {
      position: "fixed",
      top: 50,
      backgroundColor: theme.palette.background.paper,
      marginRight: theme.spacing.unit * 1,
      boxShadow: theme.shadows[2],
      [theme.breakpoints.down("sm")]: {
        top: 0,
      },
    },
    version: {
      margin: theme.spacing.unit * 2,
    },
  });

const mapGroups = (resources, permissions, hasDashboard) => {
  let groups = (hasDashboard
    ? [
        {
          path: "/",
          name: "dashboard",
          icon: DashboardIcon,
          options: {
            group: "dashboard",
            accessible: true,
          },
        },
      ]
    : []
  )
    .concat(resources.filter((r) => r.hasList && r.options.menu !== false))
    .concat(
      customRoutes
        .filter((cr) => cr.props.options.menu !== false)
        .map((cr) => ({
          path: cr.props.path,
          icon: cr.props.options.icon,
          options: cr.props.options,
        }))
    )
    .filter(
      (item) =>
        permissions &&
        (item.options.roles === undefined ||
          item.options.roles.filter((role) => permissions(role)).length > 0)
    )
    .reduce((groups, resource) => {
      let groupName = resource.options ? resource.options.group : "";
      let group = groups.find((g) => g.name === groupName);
      if (group) {
        group.resources.push(resource);
      } else {
        group = {
          name: groupName,
          order: groupOrders[groupName] || 1000,
          resources: [resource],
        };
        groups.push(group);
      }
      return groups;
    }, []);
  groups.sort((a, b) => (a.order > b.order ? 1 : a.order < b.order ? -1 : 0));

  return groups;
};
const Menu = ({
  resources,
  onMenuClick,
  logout,
  open,
  classes,
  translate,
  hasDashboard,
  width,
}) => {
  const [visibility, setVisibility] = useState({
    dashboard: true,
    reporting: false,
    admin: false,
  });
  return (
    <WithPermissions
      render={({ permissions }) => (
        <List
          component="nav"
          style={{
            height: window.innerHeight - (isWidthUp("md", width) ? 64 : 0),
          }}
          className={classnames(
            classes.root,
            open ? classes.opened : classes.closed
          )}
        >
          <FileSystemNavigator open={open} permissions={permissions} />
          {mapGroups(resources, permissions, hasDashboard).map((group) => (
            <SubMenu
              key={group.name}
              handleToggle={() =>
                setVisibility({
                  ...visibility,
                  [group.name]: visibility[group.name] !== true,
                })
              }
              isOpen={visibility[group.name]}
              sidebarIsOpen={open}
              name={`menu.groups.${group.name}`}
              icon={<SubMenuIcon />}
            >
              {group.resources.map((resource) => {
                let to = `${resource.path || `/${resource.name}`}`;
                return (
                  <MenuItemLink
                    key={resource.path || resource.name}
                    to={to}
                    isActive={(match, location) =>
                      location.pathname === `/${resource.name}` ||
                      location.pathname === resource.path ||
                      location.pathname.indexOf(`/${resource.name}?`) === 0 ||
                      location.pathname.indexOf(`/${resource.name}/`) === 0
                    }
                    primaryText={
                      resource.options && resource.options.title
                        ? resource.options.title
                        : resource.options && resource.options.label
                        ? translate(resource.options.label)
                        : translate(`menu.items.${resource.name}`)
                    }
                    leftIcon={createElement(resource.icon)}
                    onClick={onMenuClick}
                  />
                );
              })}
            </SubMenu>
          ))}
          <Typography
            className={classes.version}
            variant="caption"
            component="p"
          >
            {VERSION}
          </Typography>
          <Responsive
            small={logout}
            medium={null} // Pass null to render nothing on larger devices
          />
        </List>
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  open: state.admin.ui.sidebarOpen,
  resources: getResources(state),
});
export default compose(
  translate,
  withRouter,
  connect(mapStateToProps),
  withStyles(styles)
)(withWidth()(Menu));
