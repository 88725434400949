import React from "react";
import { Create, SimpleForm, ImageInput, ImageField } from "react-admin";

const LoginImageCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ImageInput source="file" accept="image/*" multiple={false}>
        <ImageField source="data" title="name" />
      </ImageInput>
    </SimpleForm>
  </Create>
);

export default LoginImageCreate;
