import React from "react";
import PropTypes from "prop-types";
import shouldUpdate from "recompose/shouldUpdate";
import PageviewIcon from "@material-ui/icons/Pageview";
import { Link } from "react-router-dom";
import { Responsive, Button } from "react-admin";

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = e => e.stopPropagation();

const EditButton = ({
  basePath = "",
  label = "resources.archives.actions.view_logs",
  record = {},
  source = "id",
  icon = <PageviewIcon />,
  currentSort,
  ...rest
}) => (
  <Responsive
    medium={
      <Button
        component={Link}
        to={`/archive-logs?filter=${JSON.stringify({
          archive_id: record.id
        })}`}
        onClick={stopPropagation}
        {...rest}
      >
        {icon}
      </Button>
    }
    large={
      <Button
        component={Link}
        to={`/archive-logs?filter=${JSON.stringify({
          archive_id: record.id
        })}`}
        label={label}
        onClick={stopPropagation}
        {...rest}
      >
        {icon}
      </Button>
    }
  />
);

EditButton.propTypes = {
  currentSort: PropTypes.any,
  basePath: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  label: PropTypes.string,
  record: PropTypes.object,
  icon: PropTypes.element
};

const enhance = shouldUpdate(
  (props, nextProps) =>
    props.translate !== nextProps.translate ||
    (props.record &&
      nextProps.record &&
      props.record.id !== nextProps.record.id) ||
    props.basePath !== nextProps.basePath ||
    (props.record == null && nextProps.record != null) ||
    (props.record.perms &&
      (nextProps.record.perms !== props.record.perms ||
        props.record.perms.can_edit !== nextProps.record.perms.can_edit)) ||
    props.currentSort !== nextProps.currentSort
);

export default enhance(EditButton);
