import { Loading, Query } from "react-admin";
import {
  Login,
  LoginForm,
  showNotification,
  translate,
  withDataProvider,
} from "react-admin";
import React, { useEffect } from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";

import { APP_URL } from "./config";
import { Button } from "@material-ui/core";
import { USER_EXTERNAL_AUTH_REQUEST } from "./addUserFeature";
import { compose } from "recompose";
import { connect } from "react-redux";
import { push } from "react-router-redux";

const styles = (theme) =>
  createStyles({
    background: {
      backgroundColor: "GhostWhite",
    },
    version: {
      position: "fixed",
      bottom: theme.spacing.unit,
      right: theme.spacing.unit,
    },
    reset: {
      marginLeft: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2,
    },
  });

const LoginPage = ({
  classes,
  translate,
  showNotification,
  push,
  dataProvider,
}) => {
  useEffect(() => {
    const url = document.location.href.split("?");
    if (url.length > 1) {
      let queryString = new URLSearchParams(url[1]);
      let r = queryString.get("r");
      if (r && r !== null) {
        dataProvider(USER_EXTERNAL_AUTH_REQUEST, "users", { r }).then(
          ({ data }) => {
            if (data.error === true) {
              showNotification(data.message, "warning");
            } else {
              localStorage.setItem("username", data.username);
              localStorage.setItem("token", data.token);
              localStorage.setItem("roles", JSON.stringify(data.roles));
              push("/");
            }
          }
        );
      }
    }
  }, [dataProvider, push, showNotification]);
  return (
    <Query type="GET_RANDOM_IMAGE" resource="login-images">
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }
        return (
          <Login
            backgroundImage={
              !error && data !== null && data.path !== null
                ? APP_URL + data.path
                : ""
            }
            className={classes.background}
            loginForm={
              <div>
                <LoginForm />
                <Button
                  color="primary"
                  size="small"
                  className={classes.reset}
                  href="#/reset-password"
                >
                  {translate("ra.auth.forgot_password")} &rarr;
                </Button>
              </div>
            }
          />
        );
      }}
    </Query>
  );
};
export default compose(
  translate,
  withStyles(styles),
  connect(null, { showNotification, push })
)(withDataProvider(LoginPage));
