import React from "react";
import { Create, SimpleForm, TextInput, BooleanInput } from "react-admin";
import Props from "./Props";

const FormCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="code" />
      <TextInput source="name" />
      <TextInput source="group_by" />
      <BooleanInput source="is_required" />
      <BooleanInput source="use_for_containers" />
      <BooleanInput source="use_for_assets" />
      <Props source="props" />
    </SimpleForm>
  </Create>
);

export default FormCreate;
