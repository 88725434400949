import { WithPermissions, translate } from "react-admin";

import CustomAppBar from "./CustomAppBar";
import { Hidden } from "@material-ui/core";
import MyUserMenu from "../UserMenu";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { compose } from "recompose";
import logo from "../assets/logo.png";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    verticalAlgin: "bottom",
    fontWeight: "normal",
    marginBottom: -8,
    fontSize: 15,
  },
  spacer: {
    flex: 1,
  },
  logo: {
    marginRight: theme.spacing.unit,
  },
});

const MyAppBar = ({ translate, classes, ...props }) => {
  return (
    <CustomAppBar {...props} userMenu={<MyUserMenu />}>
      <img className={classes.logo} src={logo} width="130px" alt="SensIC" />
      <Typography variant="title" color="inherit" className={classes.title} />
      <Hidden smDown>
        <WithPermissions
          render={({ permissions }) =>
            translate("app.welcome", {
              username: localStorage.getItem("username"),
            })
          }
        />
      </Hidden>
    </CustomAppBar>
  );
};
export default compose(withStyles(styles), translate)(MyAppBar);
