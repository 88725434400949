const AGGREGATORS = {
  SUM: (a, b) => (a || 0) + b,
  MIN: (a, b) => (a < b ? a : b),
  MAX: (a, b) => (a > b ? a : b),
  AVG: (a, b) => ((a || 0) + b) / 2
};
export default ({ query, ids, data }) => {
  const { columns } = query;
  const aggregations = columns.filter(c => c.aggregator !== null);
  if (aggregations.length === 0) {
    return {
      ids,
      data
    };
  }

  const aggregation = ids.reduce(
    (aggregator, id) => ({
      ...aggregator,
      ...aggregations.reduce(
        (subAggregation, aggregateColumn) => ({
          ...subAggregation,
          [aggregateColumn.name]: AGGREGATORS[aggregateColumn.aggregator](
            aggregator[aggregateColumn.name],
            parseFloat(data[id][aggregateColumn.name])
          )
        }),
        {}
      )
    }),
    {
      id: Math.max(...ids) + 1,
      ...columns
        .filter(c => c.aggregator === null)
        .reduce(
          (obj, column) => ({
            ...obj,
            [column.name]: null
          }),
          {}
        )
    }
  );
  return {
    ids: ids.concat([aggregation.id]),
    data: {
      ...data,
      [aggregation.id]: aggregation
    }
  };
};
