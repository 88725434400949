import React from "react";
import { Edit } from "react-admin";
import Form from "./Form";

const QueryCreate = props => (
  <Edit {...props} undoable={false}>
    <Form />
  </Edit>
);
export default QueryCreate;
