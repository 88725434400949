import { Admin, Resource } from "react-admin";

import { API_URL } from "./config";
import Backend from "react-dnd-html5-backend";
import { Dashboard } from "./dashboard";
import { DndProvider } from "react-dnd";
import Layout from "./components/Layout";
import LoginPage from "./LoginPage";
import Menu from "./Menu";
import React from "react";
import addArchiveFeature from "./addArchiveFeature";
import addAuthHeaderFeature from "./addAuthHeaderFeature";
import addDashboardFeature from "./addDashboardFeature";
import addExplorerFeature from "./addExplorerFeature";
import addLoginImageFeature from "./addLoginImageFeature";
import addQueriesFeature from "./addQueriesFeature";
import addRecyclerFeature from "./addRecyclerFeature";
import addUploadFeature from "./addUploadFeature";
import addUserFeature from "./addUserFeature";
import archiveLogs from "./archive-logs";
import archives from "./archives";
import authProvider from "./authProvider";
import createResource from "./queries/createResource";
import dataProvider from "./dataProvider";
import englishMessages from "ra-language-english";
import errorSagas from "./sagas/errors";
import explorer from "./explorer";
import forms from "./forms";
import getHeaders from "./authHeaders";
import groups from "./groups";
import italianMessages from "./i18n/it";
import loginImages from "./login-images";
import maps from "./maps";
import queries from "./queries";
import recycler from "./explorer/recycler";
import routes from "./routes";
import theme from "./theme";
import userPasswordChange from "./user-password-change";
import userProfile from "./user-profile";
import users from "./users";

const messages = {
  it: italianMessages,
  en: englishMessages,
};
const i18nProvider = (locale) => messages[locale];

let dp = addUploadFeature(dataProvider);
dp = addAuthHeaderFeature(dp);
dp = addExplorerFeature(dp);
dp = addRecyclerFeature(dp);
dp = addDashboardFeature(dp);
dp = addQueriesFeature(dp);
dp = addLoginImageFeature(dp);
dp = addArchiveFeature(dp);
dp = addUserFeature(dp);

const basicResources = [
  <Resource name="explorer" {...explorer} />,
  <Resource name="assets" />,
  <Resource name="containers" />,
  <Resource name="archives" {...archives} />,
  <Resource name="archive-logs" {...archiveLogs} />,
  <Resource name="maps" {...maps} />,

  <Resource name="users" {...users} />,
  <Resource name="roles" />,
  <Resource name="groups" {...groups} />,
  <Resource name="forms" {...forms} />,

  <Resource name="user-profile" {...userProfile} />,
  <Resource name="user-password-change" {...userPasswordChange} />,

  <Resource name="recycler" {...recycler} />,
  <Resource name="queries" {...queries} />,
  <Resource name="login-images" {...loginImages} />,
];

// Thanks to: https://marmelab.com/react-admin/doc/2.9/Admin.html
const fetchQueries = (permissions) =>
  fetch(`${API_URL}/queries/load`, {
    method: "GET",
    headers: getHeaders(),
  })
    .then((response) => response.json())
    .then(({ data }) =>
      (
        (data &&
          data.queries &&
          data.queries.map((query) => createResource(query))) ||
        []
      ).concat(basicResources)
    );

const App = () => (
  <DndProvider backend={Backend}>
    <Admin
      customSagas={[errorSagas]}
      customRoutes={routes}
      menu={Menu}
      theme={theme}
      locale="it"
      appLayout={Layout}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      dataProvider={dp}
      dashboard={Dashboard}
      loginPage={LoginPage}
    >
      {fetchQueries}
    </Admin>
  </DndProvider>
);
export default App;
