import React from "react";
import {
  List,
  ChipField,
  Datagrid,
  TextField,
  EditButton,
  DateField,
  BooleanField
} from "react-admin";
import Filters from "./Filters";
import BulkActions from "../components/list/BulkActions";
const PropSchemaList = props => (
  <React.Fragment>
    <List
      {...props}
      perPage={10}
      filters={<Filters />}
      bulkActionButtons={<BulkActions />}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <BooleanField source="is_required" sortable={false} />
        <BooleanField source="use_for_containers" sortable={false} />
        <BooleanField source="use_for_assets" sortable={false} />

        <ChipField source="group_by" />

        <TextField source="name" />
        <DateField source="modified" showTime />
        <EditButton />
      </Datagrid>
    </List>
  </React.Fragment>
);
export default PropSchemaList;
