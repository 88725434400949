import { ArchiveIcon } from "../icons";
import List from "./List";

export default {
  options: {
    group: "dashboard",
    roles: ["admin"]
  },
  icon: ArchiveIcon,
  list: List
};
