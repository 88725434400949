import React from "react";
import {
  List,
  Responsive,
  SimpleList,
  Datagrid,
  TextField,
  DateField,
  ChipField,
  EditButton
} from "react-admin";

const QueryList = props => (
  <List {...props} perPage={20}>
    <Responsive
      small={
        <SimpleList
          linkType="edit"
          primaryText={record => record.name}
          secondaryText={record => record.resource}
        />
      }
      medium={
        <Datagrid>
          <TextField source="id" />
          <ChipField source="resource" />
          <TextField source="name" />
          <DateField source="modified" showTime />
          <EditButton />
        </Datagrid>
      }
    />
  </List>
);

export default QueryList;
