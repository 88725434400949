export default [
  {
    id: "pending",
    name: "Pending"
  },
  {
    id: "working",
    name: "Working"
  },
  {
    id: "error",
    name: "Error"
  },
  {
    id: "completed",
    name: "Completed"
  }
];
