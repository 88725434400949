import React, { Fragment } from "react";
import { DateField, translate } from "react-admin";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import FolderIcon from "@material-ui/icons/Folder";
import DownloadIcon from "@material-ui/icons/FileDownload";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import BookmarkIcon from "@material-ui/icons/Bookmark";

import filesize from "../utils/filesize";
import { IconButton, Typography, Grid } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { API_URL } from "../config";
import compose from "recompose/compose";
import Link from "../components/Link";
import { Scrollbars } from "react-custom-scrollbars";
import TreeView from "../components/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "../components/TreeItem";
import { orange } from "@material-ui/core/colors";
const styles = theme =>
  createStyles({
    root: {
      width: "100%",
      margin: 0,
      backgroundColor: theme.palette.background.paper
    },
    tree: {},
    nav: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit
    },
    node: {
      padding: 0,
      width: "100%"
    },
    nodeRoot: {
      backgroundColor: "white",
      "&:focus": {
        backgroundColor: "white"
      },
      "&:hover": {
        backgroundColor: "white"
      },
      "&:active": {
        backgroundColor: "white"
      }
    },
    nodeContent: {
      borderRadius: theme.spacing.unit
    },
    nodeLabel: {
      // backgroundColor: "white"
    },
    link: {
      width: "80%"
    },
    itemIcon: {
      backgroundColor: orange[200]
    },
    modifiedField: {
      fontSize: 14,
      color: theme.palette.secondary,
      display: "inline-block"
    },
    emptyBox: {
      textAlign: "center"
    },
    emptyBoxIcon: {
      fontSize: 100
    }
  });

const createTree = ({ data }) => {
  let result = [];
  let level = { result };

  data.forEach(item => {
    let path = item.tree.substr(1, item.tree.length - 2);
    let args = path.split("/");
    if (item.type === "asset") {
      args.push(item.name);
    }
    args.reduce((r, name, i, a) => {
      if (!r[name]) {
        r[name] = { result: [] };
        r.result.push({
          type: "navigation",
          perms: {
            can_download: false
          },
          ...(i === args.length - 1 ? item : { name }),
          children: r[name].result
        });
      }

      return r[name];
    }, level);
  });

  return result;
};

const CustomLink = ({ row, children, className, download = false }) => {
  let href = "";
  if (row.type === "asset" || download) {
    href = `${API_URL}/explorer/${row.record_id}/${
      row.type
    }/download?token=${localStorage.getItem("token")}`;
  } else if (row.type === "container") {
    href = `#/explorer?filter=${JSON.stringify({
      id: row.record_id,
      path: row.path
    })}`;
  }
  return (
    <Link variant="body1" className={className} href={href}>
      {children}
    </Link>
  );
};

const renderNavigation = ({ node, classes }) => (
  <Typography component="div" key={node.name} className={classes.node}>
    <Typography component="p" variant="subheading" className={classes.nav}>
      {node.name}
    </Typography>
  </Typography>
);
const renderItem = ({ node, classes }) => (
  <Typography component="div" key={node.id} className={classes.node}>
    <Grid>
      <Grid container spacing={16} alignItems="center">
        <Grid item>
          <Avatar className={classes.itemIcon}>
            {node.type === "container" && <FolderIcon />}
            {node.type === "asset" && <InsertDriveFileIcon />}
          </Avatar>
        </Grid>
        <Grid item xs>
          <CustomLink className={classes.link} row={node}>
            {node.name}
          </CustomLink>
          <Fragment>
            {filesize(node.size)}
            <DateField
              className={classes.modifiedField}
              source={"modified"}
              record={node}
              showTime
            />
          </Fragment>
        </Grid>
        {node.perms && node.perms.can_download && (
          <Grid item>
            <IconButton edge="end" aria-label="Download">
              <CustomLink row={node} download={true}>
                <DownloadIcon />
              </CustomLink>
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  </Typography>
);

const renderNode = ({ classes, node, translate }) =>
  node.children && node.children.length > 0 ? (
    <TreeItem
      key={node.name}
      nodeId={node.name}
      classes={{
        root: classes.nodeRoot,
        content: classes.nodeContent,
        label: classes.nodeLabel
      }}
      label={
        node.type === "navigation"
          ? renderNavigation({ node, classes })
          : renderItem({ node, classes })
      }
    >
      {node.children.map(child =>
        renderNode({ classes, node: child, translate })
      )}
    </TreeItem>
  ) : (
    <TreeItem
      key={node.name}
      nodeId={node.name}
      classes={{
        root: classes.nodeRoot,
        content: classes.nodeContent,
        label: classes.nodeLabel
      }}
      label={
        node.type === "navigation"
          ? renderNavigation({ node, classes })
          : renderItem({ node, classes })
      }
    />
  );

const TreeList = withStyles(styles)(({ classes, data, translate }) => {
  if (!data || data.length === 0) {
    return (
      <div className={classes.emptyBox}>
        <WarningIcon className={classes.emptyBoxIcon} />
        <Typography variant="subheading">
          {translate("resources.maps.messages.no_matches")}
        </Typography>
      </div>
    );
  }

  const tree = createTree({ data });
  return (
    <Scrollbars
      autoHeight
      autoHeightMax={window.outerHeight - 270}
      autoHide={true}
    >
      <TreeView
        className={classes.tree}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultEndIcon={<BookmarkIcon />}
      >
        {tree.map(node => renderNode({ classes, node, translate }))}
      </TreeView>
    </Scrollbars>
  );
});

export default compose(translate)(TreeList);
