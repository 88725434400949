import React from "react";
import PropTypes from "prop-types";
import MuiBreadcrumbs from "../components/breadcrumbs/Breadcrumbs";
import LinearProgress from "@material-ui/core/LinearProgress";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Divider } from "@material-ui/core";
import compose from "recompose/compose";
import { translate } from "react-admin";
import Link from "../components/Link";

const Breadcrumbs = withStyles(theme => ({
  notesLabel: {
    paddingLeft: theme.spacing.unit * 2,
    fontWeight: "bold"
  },
  notes: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2
  },
  breadcrumbs: {
    padding: theme.spacing.unit * 2
  },
  location: {
    padding: 0,
    margin: theme.spacing.unit * 0
  },
  label: {},
  icon: {
    padding: theme.spacing.unit * 0,
    margin: theme.spacing.unit * 0
  }
}))(({ translate, classes, breadcrumbs, loading, showMode = "breadcrumbs" }) =>
  loading ? (
    <LinearProgress color="secondary" />
  ) : (
    <React.Fragment>
      {showMode === "breadcrumbs" &&
        breadcrumbs &&
        breadcrumbs.length > 0 &&
        breadcrumbs[breadcrumbs.length - 1].pinned &&
        breadcrumbs[breadcrumbs.length - 1].notes && (
          <React.Fragment>
            <Divider />
            <Typography className={classes.notesLabel} variant="caption">
              {translate("resources.explorer.fields.notes")}
            </Typography>
            <Typography className={classes.notes} variant="subheading">
              {breadcrumbs[breadcrumbs.length - 1].notes}
            </Typography>
            <Divider />
          </React.Fragment>
        )}
      <MuiBreadcrumbs
        className={classes[showMode]}
        separator={<ChevronRightIcon />}
      >
        {showMode === "location" && (
          <Typography
            variant="body1"
            component="span"
            className={classes.label}
          >
            {translate("resources.explorer.breadcrumbs.position")}
          </Typography>
        )}
        {breadcrumbs.length === 0 && showMode === "breadcrumbs" ? (
          <Typography variant={"body1"} component="span">
            {translate("resources.explorer.breadcrumbs.root")}
          </Typography>
        ) : (
          <Link
            variant="body1"
            href={`#/explorer?filter=${JSON.stringify({ id: null })}`}
          >
            {translate("resources.explorer.breadcrumbs.root")}
          </Link>
        )}
        {breadcrumbs.map((breadcrumb, idx) =>
          idx === breadcrumbs.length - 1 && showMode === "breadcrumbs" ? (
            <Typography key={breadcrumb.id} variant="body1" component="span">
              {breadcrumb.name}
            </Typography>
          ) : (
            <Link
              key={breadcrumb.id}
              variant="body1"
              href={`#/explorer?filter=${JSON.stringify({
                id: breadcrumb.record_id,
                path: breadcrumb.path
              })}`}
            >
              {breadcrumb.name}
            </Link>
          )
        )}
      </MuiBreadcrumbs>
    </React.Fragment>
  )
);
Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  showMode: PropTypes.string,
  translate: PropTypes.func
};
export default compose(translate)(Breadcrumbs);
