import React from "react";
import { withDataProvider, GET_ONE } from "ra-core";
import { compose } from "recompose";

class WithContainerProps extends React.Component {
  state = {
    id: null,
    data: null
  };
  componentDidMount() {
    const { id } = this.props;
    if (id > 0) {
      const { dataProvider } = this.props;
      dataProvider(GET_ONE, "containers", { id }).then(({ data }) =>
        this.setState({ data })
      );
    }
  }
  componentWillUpdate(nextProps) {
    if (nextProps.id !== this.props.id) {
      if (nextProps.id > 0) {
        const { dataProvider } = this.props;
        dataProvider(GET_ONE, "containers", {
          id: nextProps.id
        }).then(({ data }) => this.setState({ data }));
      } else {
        this.setState({ data: null });
      }
    }
  }
  render() {
    const { children } = this.props;
    return children(this.state);
  }
}
export default compose(withDataProvider)(WithContainerProps);
