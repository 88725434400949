import { SimpleForm, TextInput, required } from "react-admin";

import GroupsInput from "./inputs/GroupsInput";
import PermsInput from "./inputs/PermsInput";
import React from "react";
import RolesInput from "./inputs/RolesInput";
import TypeInput from "./inputs/TypeInput";

const Form = (props) => (
  <SimpleForm {...props} redirect="list">
    <TypeInput source="type" validate={required()} />
    <TextInput source="email" />
    <TextInput source="username" />
    <TextInput source="password" type="password" />
    <TextInput source="profile.name" defaultValue="" />
    <TextInput source="profile.surname" defaultValue="" />
    <RolesInput source="roles" />
    <GroupsInput source="groups" />
    <PermsInput source="perms" />
  </SimpleForm>
);

export default Form;
