// in addUploadFeature.js
/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file) =>
  file.rawFile instanceof Blob
    ? new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.rawFile);

        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      })
    : Promise.resolve(null);

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadFeature = (requestHandler) => (type, resource, params) => {
  if (
    ["CREATE", "UPDATE"].indexOf(type) !== -1 &&
    ["assets", "login-images"].indexOf(resource) !== -1
  ) {
    if (
      params.data.files &&
      params.data.files.length &&
      params.data.files.length > 0 &&
      params.data.files.filter((f) => f.rawFile && f.rawFile instanceof File)
        .length > 0
    ) {
      // Upload di più file contemporaneamente.
      return Promise.all(
        params.data.files
          .filter((f) => f.rawFile && f.rawFile instanceof File)
          .map((file) =>
            convertFileToBase64(file).then((convertedFile) => ({
              data: convertedFile,
              name: file.rawFile.name,
              size: file.rawFile.size,
              type: file.rawFile.type,
            }))
          )
      ).then((files) =>
        requestHandler(type, resource, {
          ...params,
          data: {
            ...params.data,
            files,
          },
        })
      );
    }
    if (
      params.data.file &&
      params.data.file.rawFile &&
      params.data.file.rawFile instanceof File
    ) {
      return convertFileToBase64(params.data.file).then((convertedFile) =>
        requestHandler(type, resource, {
          ...params,
          data: {
            ...params.data,
            file:
              convertedFile !== null
                ? {
                    data: convertedFile,
                    name: params.data.file.rawFile.name,
                    size: params.data.file.rawFile.size,
                    type: params.data.file.rawFile.type,
                  }
                : null,
          },
        })
      );
    }
  }
  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params);
};

export default addUploadFeature;
