import React from "react";
import { List, Datagrid, TextField } from "react-admin";

const LoginImageList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
    </Datagrid>
  </List>
);

export default LoginImageList;
