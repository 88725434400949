import React from "react";
import { Create, SimpleForm, TextInput, translate, Toolbar } from "react-admin";
import CustomSaveButton from "./components/CustomSaveButton";

const PasswordChangeToolbar = (props) => (
  <Toolbar {...props}>
    <CustomSaveButton />
  </Toolbar>
);

const PasswordChangeCreate = ({ translate, ...props }) => (
  <Create {...props} title={translate("menu.items.password-change")}>
    <SimpleForm redirect={false} toolbar={<PasswordChangeToolbar />}>
      <TextInput source="old_password" type="password" />
      <TextInput source="password" type="password" />
      <TextInput source="confirm_password" type="password" />
    </SimpleForm>
  </Create>
);

export default translate(PasswordChangeCreate);
