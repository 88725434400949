import React from "react";
import {
  ArrayInput,
  BooleanInput,
  SelectInput,
  FormDataConsumer
} from "react-admin";
import formatters from "../columns/formatters";
import aggregators from "../columns/aggregators";
import SimpleFormIterator from "../../components/forms/SimpleFormIterator";
import ColumnAliasInput from "./ColumnAliasInput";

const style = {
  display: "inline",
  float: "left",
  marginRight: "20px",
  width: "auto"
};
const ColumnsInput = props => (
  <ArrayInput {...props} style={{ width: "100%" }} label={""}>
    <SimpleFormIterator disableAdd disableRemove>
      <FormDataConsumer>
        {({ formData, scopedFormData, getSource, ...rest }) => (
          <ColumnAliasInput
            source={getSource("alias")}
            style={style}
            scopedFormData={scopedFormData}
            {...rest}
          />
        )}
      </FormDataConsumer>

      <SelectInput
        label="resources.queries.fields.columns.fields.aggregator"
        source="aggregator"
        fullWidth
        style={{ ...style, minWidth: 182, display: "block" }}
        choices={aggregators.map(aggregator => ({
          id: aggregator,
          name: aggregator
        }))}
      />
      <SelectInput
        label="resources.queries.fields.columns.fields.formatter"
        source="formatter"
        fullWidth
        style={{ ...style, minWidth: 182, display: "block" }}
        choices={Object.keys(formatters).map(name => ({ id: name, name }))}
      />

      <BooleanInput
        label="resources.queries.fields.columns.fields.is_hidden"
        source="is_hidden"
        style={style}
      />
    </SimpleFormIterator>
  </ArrayInput>
);
export default ColumnsInput;
