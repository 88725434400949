import {
  ArrayField,
  ChipField,
  DatagridBody,
  DateField,
  Pagination as DefaultPagination,
  SingleFieldList,
} from "react-admin";
import { GET_LIST, withDataProvider } from "ra-core";
import React, { Component, Fragment } from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";

import Actions from "./Actions";
import ArchiveWarn from "./ArchiveWarn";
import Breadcrumbs from "./Breadcrumbs";
import BulkActions from "../components/list/BulkActions";
import ClickableEyeField from "./fields/ClickableEyeField";
import CloneButton from "./buttons/CloneButton";
import Drawer from "@material-ui/core/Drawer";
import EditButton from "./buttons/EditButton";
import EmptyMessageBox from "./EmptyMessageBox";
import Filters from "./Filters";
import IconField from "./fields/IconField";
import LinkField from "./fields/LinkField";
import ListRow from "./ListRow";
import NavText from "./fields/NavText";
import PropTypes from "prop-types";
import ResponsiveGrid from "../components/list/ResponsiveGrid";
import ResponsiveList from "../components/list/ResponsiveList";
import RestoreButton from "./buttons/RestoreButton";
import SizeField from "./fields/SizeField";
import View from "./View";
import compose from "recompose/compose";
import { get } from "lodash";
import makeid from "../utils/makeid";

const Pagination = ({ total, ...props }) =>
  total > 0 ? (
    <DefaultPagination
      {...props}
      rowsPerPageOptions={[10, 25, 50, 100]}
      total={total}
    />
  ) : null;
const breadcrumbs = (props) => {
  const { location } = props.filterValues.id
    ? props.data["prev"] || { location: [] }
    : { location: [] };
  return location;
};
const styles = (theme) =>
  createStyles({
    drawerPaper: {
      margin: 0,
      overflow: "hidden",
    },
  });
const MyDatagrid = ({ onViewClick, basePath, ...props }) => (
  <React.Fragment>
    <ArchiveWarn id={props.filterValues.id} />
    <ResponsiveGrid {...props} body={<DatagridBody row={<ListRow />} />}>
      <LinkField
        source="type"
        sort={props.currentSort}
        sortable={false}
        basePath={basePath}
      >
        <IconField />
      </LinkField>
      <LinkField
        draggable={true}
        source="name"
        sort={props.currentSort}
        basePath={basePath}
        search={
          !!get(props, "filterValues.q") ||
          !!get(props, "filterValues.view_kind")
        }
      >
        <NavText />
      </LinkField>
      <LinkField source="size" sort={props.currentSort} basePath={basePath}>
        <SizeField />
      </LinkField>
      <ArrayField source="groups" sortable={false}>
        <SingleFieldList linkType={false}>
          <ChipField source="code" />
        </SingleFieldList>
      </ArrayField>
      <LinkField
        source={basePath === "/recycler" ? "deleted" : "modified"}
        sort={props.currentSort}
        basePath={basePath}
      >
        <DateField showTime />
      </LinkField>
      <ClickableEyeField onClick={onViewClick} />
      {basePath === "/explorer" && (
        <EditButton
          basePath={basePath}
          currentSort={props.currentSort}
          page={props.page}
          perPage={props.perPage}
        />
      )}
      {basePath === "/explorer" && <CloneButton basePath={basePath} />}
      {basePath === "/recycler" && <RestoreButton />}
    </ResponsiveGrid>
    <EmptyMessageBox {...props} />
    <Breadcrumbs loading={props.isLoading} breadcrumbs={breadcrumbs(props)} />
  </React.Fragment>
);

class ExploreList extends Component {
  state = {
    selected: null,
    forms: [],
    key: makeid(8),
  };

  constructor(props) {
    super(props);

    this.handleOpenView = this.handleOpenView.bind(this);
    this.handleCloseView = this.handleCloseView.bind(this);
  }

  componentDidMount() {
    const { dataProvider } = this.props;
    dataProvider(GET_LIST, "forms", {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "name", order: "DESC" },
    }).then(({ data }) => {
      this.setState({ forms: data, key: makeid(8) });
    });
  }

  handleCloseView() {
    this.setState({ selected: null });
  }
  handleOpenView(record) {
    this.setState({ selected: record });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== !this.props.location.search) {
      this.setState({ selected: null });
    }
  }

  render() {
    const {
      classes,
      permissions,
      dataProvider,
      dispatch,
      ...props
    } = this.props;
    return (
      <Fragment>
        <ResponsiveList
          {...props}
          key={this.state.key}
          sort={{ field: "name", order: "asc" }}
          filters={<Filters key={this.state.key} forms={this.state.forms} />}
          title="resources.explorer.name"
          actions={<Actions permissions={permissions || {}} />}
          bulkActionButtons={<BulkActions />}
          pagination={<Pagination />}
          split
          type="table"
        >
          <MyDatagrid onViewClick={this.handleOpenView} />
        </ResponsiveList>
        <Drawer
          variant="persistent"
          open={this.state.selected !== null}
          anchor="right"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
          {this.state.selected && (
            <View
              onCancel={this.handleCloseView}
              record={this.state.selected}
            />
          )}
        </Drawer>
      </Fragment>
    );
  }
}
ExploreList.propTypes = {
  classes: PropTypes.object.isRequired,
  permissions: PropTypes.any,
};

export default compose(withStyles(styles), withDataProvider)(ExploreList);
