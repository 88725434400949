import React from "react";
import { Typography } from "@material-ui/core";
import { get } from "lodash";

const NavText = ({
  record,
  source,
  className,
  basePath,
  resource,
  ...props
}) => {
  return (
    <Typography
      variant="body1"
      component="span"
      className={className}
      {...props}
    >
      {get(record, source)}
    </Typography>
  );
};
export default NavText;
