const expressions = {
  EQ: "eq",
  NOT_EQ: "not_eq",
  EMPTY: "empty",
  NOT_EMPTY: "not_empty",
  LOWER: "lower",
  LOWER_OR_EQ: "lower_or_eq",
  GREATER: "greater",
  GREATER_OR_EQ: "greater_or_eq",
  IN: "in",
  NOT_IN: "not_in",
  LIKE: "like",
  ENDS_WITH: "ends_with",
  STARTS_WITH: "starts_with",
  CHOSEN_BY_USER: "chosen_by_user"
};
export function isValuelessExpression(expression) {
  return (
    expression === expressions.EMPTY || expression === expressions.NOT_EMPTY
  );
}
export default expressions;
