import {
  BooleanInput,
  Edit,
  FileField,
  FileInput,
  LongTextInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  translate,
} from "react-admin";
import Form, {
  FormSelectItem,
  ensureFormRulesAreRespected,
  formatForms,
  parseForms,
  reloadForms,
} from "./input/Form";
import React, { Fragment, useState } from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";

import CheckInOutStatusField from "./fields/CheckInOutStatusField";
import { FormDataConsumer } from "react-admin";
import { FormHelperText } from "@material-ui/core";
import FormToolbar from "./FormToolbar";
import Location from "./Location";
import LogsView from "./LogsView";
import PermsInput from "./input/Perms";
import { REDUX_FORM_NAME } from "ra-core";
import UserPermsInput from "../users/inputs/PermsInput";
import { change } from "redux-form";
import compose from "recompose/compose";
import makeid from "../utils/makeid";
import withForms from "../forms/withForms";
import withMaps from "../maps/withMaps";

const styles = createStyles((theme) => ({
  select: {
    marginRight: theme.spacing.unit * 2,
    minWidth: 200,
  },
}));

/**
 * Reconstruct state necessary to prepare the UI to the next operation.
 * Containers and assets share the same props.
 */
const parseRoute = (fn) => {
  let route = document.location.toString().split("#")[1];
  let args = route.split("/");
  let type = args[args.length - 2];
  let container = args[args.length - 3];
  let defaults = {
    type: type,
    container_id: parseInt(container, 10) || null,
    perms: [
      {
        group_id: null,
      },
    ],
  };
  return fn({
    type,
    container,
    defaults,
  });
};
const redirect = ({ search }, id, basePath, record) =>
  `/explorer?filter=${JSON.stringify({
    id: record.container_id,
  })}&reload=${makeid(5)}${
    search && search !== null && search.length > 0
      ? "&" + search.substring(1)
      : ""
  }`;

const ExplorerEdit = ({
  translate,
  classes,
  permissions,
  dispatch,
  forms,
  maps,
  ...props
}) => {
  const [showLogs, setShowLogs] = useState(false);
  return parseRoute((routeData) => (
    <Edit
      {...props}
      resource={routeData.type}
      title={`resources.explorer.name`}
      undoable={false}
    >
      <SimpleForm
        toolbar={
          <FormToolbar
            permissions={permissions}
            onShowLogsRequest={() => setShowLogs(true)}
          />
        }
        redirect={redirect.bind(this, props.location)}
      >
        <FormDataConsumer>
          {({ formData, dispatch, record }) => (
            <Fragment>
              <Location id={formData.container_id} />
              {!record.can_checkin && (
                <TextInput
                  source="name"
                  label="resources.explorer.fields.name"
                  style={{ display: "block", clear: "both" }}
                  options={{
                    fullWidth: true,
                  }}
                />
              )}
              {routeData.type === "assets" && record.can_checkin && (
                <FileInput source="files" multiple={true}>
                  <FileField source="src" title="Title" />
                </FileInput>
              )}
              <ReferenceArrayInput
                source="forms"
                reference="forms"
                resource="explorer"
                className={classes.select}
                filter={{
                  usage: routeData.type,
                }}
                format={formatForms}
                parse={parseForms}
                onChange={(e, selection) => {
                  let safeSelection = ensureFormRulesAreRespected(
                    forms,
                    selection,
                    routeData.type
                  );
                  dispatch(
                    change(
                      REDUX_FORM_NAME,
                      "props",
                      reloadForms({
                        value: safeSelection,
                        forms,
                        formData,
                      })
                    )
                  );
                }}
              >
                <SelectArrayInput
                  optionText={(choice) => (
                    <FormSelectItem
                      choice={choice}
                      forms={forms}
                      formData={formData}
                    />
                  )}
                />
              </ReferenceArrayInput>
              <br />
              <ReferenceInput
                source="map_id"
                reference="maps"
                resource="explorer"
                className={classes.select}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              <SelectInput
                source="map_shape_id"
                choices={
                  (formData &&
                    formData.map_id &&
                    maps[formData.map_id] &&
                    maps[formData.map_id].shapes) ||
                  []
                }
              />

              {formData &&
                formData.forms &&
                formData.forms.map((form, idx) => (
                  <Form source="props" form={form} key={idx} />
                ))}

              <LongTextInput
                source="notes"
                label="resources.explorer.fields.notes"
              />
              {routeData.type === "containers" && (
                <BooleanInput
                  source="is_pinned"
                  label="resources.explorer.fields.is_pinned"
                />
              )}
              {routeData.type === "containers" && (
                <>
                  <FormHelperText>
                    {translate("resources.explorer.fields.is_pinned_help")}
                  </FormHelperText>
                </>
              )}
              <BooleanInput
                source="is_priority"
                label="resources.explorer.fields.is_priority"
              />
              <>
                <FormHelperText>
                  {translate("resources.explorer.fields.is_priority_help")}
                </FormHelperText>
              </>
              {routeData.type === "containers" &&
                permissions &&
                permissions.can_edit_perms && (
                  <PermsInput
                    source="perms"
                    label="resources.explorer.fields.perms.name"
                    resource={`perms`}
                    objectType={routeData.type}
                    formData={formData}
                    disableRemove={false}
                  />
                )}
              {routeData.type === "containers" &&
                permissions &&
                permissions.can_edit_perms === true && (
                  <UserPermsInput
                    source="user_perms"
                    label="resources.explorer.fields.user_perms.name"
                    resource={`perms`}
                    mode="containers"
                  />
                )}
              <LogsView
                id={formData && formData !== null ? formData.id : null}
                type={routeData.type}
                open={showLogs}
                onClose={() => setShowLogs(false)}
              />
            </Fragment>
          )}
        </FormDataConsumer>
        <CheckInOutStatusField type={routeData.type} />
      </SimpleForm>
    </Edit>
  ));
};
export default compose(
  withStyles(styles),
  withForms,
  withMaps,
  translate
)(ExplorerEdit);
