import React from "react";
import { MapControl, withLeaflet } from "react-leaflet";
import L from "leaflet";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import compose from "recompose/compose";
import { createStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { translate } from "react-admin";

const styles = theme =>
  createStyles({
    select: {
      border: "2px solid rgba(0, 0, 0, 0.2)",
      borderColor: "rgba(0, 0, 0, 0.2)",
      borderRadius: 4,
      backgroundColor: "white",
      color: "black",
      width: 150,
      paddingLeft: theme.spacing.unit * 1
    },
    item: {}
  });

class MapFilter extends MapControl {
  constructor(props) {
    super(props);
    this.state = {
      id: 0
    };
  }

  componentDidMount() {
    super.componentDidMount();
    ReactDOM.render(this.renderSelect(), this.container);
  }

  onChange(evt) {
    const { onChange } = this.props;
    this.setState({ id: evt.target.value });
    onChange(evt.target.value);
  }

  renderSelect() {
    const { classes, maps, translate } = this.props;
    return (
      <Select
        className={classes.select}
        value={this.state.id}
        onChange={this.onChange.bind(this)}
        disableUnderline
      >
        <MenuItem value={0}>{translate("menu.items.maps")}</MenuItem>
        {maps.map(m => (
          <MenuItem key={m.id} value={m.id}>
            {m.name}
          </MenuItem>
        ))}
      </Select>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.id !== this.state.id || prevProps !== this.props) {
      ReactDOM.render(this.renderSelect(), this.container);
    }
  }

  createLeafletElement(opts) {
    const MapStatus = L.Control.extend({
      onAdd: map => {
        this.container = L.DomUtil.create("div", "info");
        return this.container;
      }
    });
    return new MapStatus({ position: "topleft" });
  }
}

MapFilter.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(withLeaflet, withStyles(styles), translate)(MapFilter);
