import React from "react";
import { TextInput } from "react-admin";
const ColumnAliasInput = ({ source, record, scopedFormData, ...props }) => (
  <TextInput
    {...props}
    source={source}
    record={record}
    label={`${record.name || scopedFormData.name} (${record.type ||
      scopedFormData.type})`}
  />
);
export default ColumnAliasInput;
