import Types from "./types";
import Expressions from "./expressions";

export default {
  [Types.CHAR]: [
    Expressions.EQ,
    Expressions.NOT_EQ,
    Expressions.EMPTY,
    Expressions.NOT_EMPTY,
    Expressions.LIKE,
    Expressions.STARTS_WITH,
    Expressions.ENDS_WITH,
    Expressions.IN,
    Expressions.NOT_IN,
    Expressions.CHOSEN_BY_USER
  ],
  [Types.VARCHAR]: [
    Expressions.EQ,
    Expressions.NOT_EQ,
    Expressions.EMPTY,
    Expressions.NOT_EMPTY,
    Expressions.LIKE,
    Expressions.STARTS_WITH,
    Expressions.ENDS_WITH,
    Expressions.IN,
    Expressions.NOT_IN,
    Expressions.CHOSEN_BY_USER
  ],
  [Types.TEXT]: [
    Expressions.EQ,
    Expressions.NOT_EQ,
    Expressions.EMPTY,
    Expressions.NOT_EMPTY,
    Expressions.LIKE,
    Expressions.STARTS_WITH,
    Expressions.ENDS_WITH,
    Expressions.IN,
    Expressions.NOT_IN,
    Expressions.CHOSEN_BY_USER
  ],
  [Types.INT]: [
    Expressions.EQ,
    Expressions.NOT_EQ,
    Expressions.EMPTY,
    Expressions.NOT_EMPTY,
    Expressions.GREATER,
    Expressions.GREATER_OR_EQ,
    Expressions.LOWER,
    Expressions.LOWER_OR_EQ,
    Expressions.CHOSEN_BY_USER
  ],
  [Types.TINYINT]: [
    Expressions.EQ,
    Expressions.NOT_EQ,
    Expressions.EMPTY,
    Expressions.NOT_EMPTY,
    Expressions.GREATER,
    Expressions.GREATER_OR_EQ,
    Expressions.LOWER,
    Expressions.LOWER_OR_EQ,
    Expressions.CHOSEN_BY_USER
  ],
  [Types.BIGINT]: [
    Expressions.EQ,
    Expressions.NOT_EQ,
    Expressions.EMPTY,
    Expressions.NOT_EMPTY,
    Expressions.GREATER,
    Expressions.GREATER_OR_EQ,
    Expressions.LOWER,
    Expressions.LOWER_OR_EQ,
    Expressions.CHOSEN_BY_USER
  ],
  [Types.DECIMAL]: [
    Expressions.EQ,
    Expressions.NOT_EQ,
    Expressions.EMPTY,
    Expressions.NOT_EMPTY,
    Expressions.GREATER,
    Expressions.GREATER_OR_EQ,
    Expressions.LOWER,
    Expressions.LOWER_OR_EQ,
    Expressions.CHOSEN_BY_USER
  ],
  [Types.DOUBLE]: [
    Expressions.EQ,
    Expressions.NOT_EQ,
    Expressions.EMPTY,
    Expressions.NOT_EMPTY,
    Expressions.GREATER,
    Expressions.GREATER_OR_EQ,
    Expressions.LOWER,
    Expressions.LOWER_OR_EQ,
    Expressions.CHOSEN_BY_USER
  ],
  [Types.BOOL]: [
    Expressions.EQ,
    Expressions.EMPTY,
    Expressions.NOT_EMPTY,
    Expressions.CHOSEN_BY_USER
  ],
  [Types.DATE]: [
    Expressions.EQ,
    Expressions.EMPTY,
    Expressions.NOT_EMPTY,
    Expressions.NOT_EQ,
    Expressions.GREATER,
    Expressions.GREATER_OR_EQ,
    Expressions.LOWER,
    Expressions.LOWER_OR_EQ,
    Expressions.CHOSEN_BY_USER
  ],
  [Types.DATETIME]: [
    Expressions.EQ,
    Expressions.EMPTY,
    Expressions.NOT_EMPTY,
    Expressions.NOT_EQ,
    Expressions.GREATER,
    Expressions.GREATER_OR_EQ,
    Expressions.LOWER,
    Expressions.LOWER_OR_EQ,
    Expressions.CHOSEN_BY_USER
  ]
};
