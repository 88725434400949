import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { compose } from "recompose";
import { grey } from "@material-ui/core/colors";
import { withTranslate } from "react-admin";

const SearchHelpLink = withStyles((theme) => ({
  expressionExample: {
    margin: theme.spacing.unit * 2,
    "& code": {
      padding: theme.spacing.unit * 0.5,
      backgroundColor: grey[200],
      clear: "both",
    },
    "& p": {
      marginTop: theme.spacing.unit,
    },
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary[500],
  },
}))(({ label = "resources.explorer.search_help.link", classes, translate }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  return (
    <span>
      <a
        className={classes.link}
        href="#/explorer?view=search-help"
        onClick={handleOpen}
      >
        {translate(label)}
      </a>
      <Dialog open={open} onClose={handleClose} scroll={"paper"}>
        <DialogTitle id="scroll-dialog-title">
          {translate("resources.explorer.search_help.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {translate("resources.explorer.search_help.paragraph1")}
          </DialogContentText>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {translate("resources.explorer.search_help.column_char")}
                </TableCell>
                <TableCell>
                  {translate(
                    "resources.explorer.search_help.column_description"
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[
                "plus",
                "minus",
                "empty",
                "distance",
                "gt_or_lt",
                "brackets",
                "tilde",
                "asterisk",
                "apex",
              ].map((char) => (
                <TableRow key={char}>
                  <TableCell>
                    <Typography variant="body2" component="div">
                      {translate(
                        `resources.explorer.search_help.symbol.${char}`
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" component="div">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: translate(
                            `resources.explorer.search_help.description.${char}`
                          ),
                        }}
                      />
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <br />
          <DialogContentText component="div">
            <Typography variant="body1" align="justify">
              <span
                dangerouslySetInnerHTML={{
                  __html: translate(
                    "resources.explorer.search_help.paragraph2"
                  ),
                }}
              />
            </Typography>
            {[
              "example_1",
              "example_2",
              "example_3",
              "example_4",
              "example_5",
              "example_6",
              "example_7",
              "example_8",
            ].map((ex) => (
              <Typography
                variant="body1"
                key={ex}
                component="div"
                className={classes.expressionExample}
              >
                <code>
                  {translate(
                    `resources.explorer.search_help.examples.${ex}_expr`
                  )}
                </code>
                <p
                  dangerouslySetInnerHTML={{
                    __html: translate(
                      `resources.explorer.search_help.examples.${ex}_descr`
                    ),
                  }}
                />
              </Typography>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {translate("resources.explorer.search_help.close_button")}
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
});

export default compose(withTranslate)(SearchHelpLink);
