import React, { Fragment, useState } from "react";
import { SearchInput, withTranslate } from "react-admin";

import SearchHelpLink from "../SearchHelpLink";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core";

const stopPropagation = (e) => {
  e.stopPropagation();
  e.preventDefault();
  return false;
};
const SearchExpressionInput = withStyles((theme) => ({
  input: {
    minWidth: 300,
  },
}))(
  ({
    classes,
    translate,
    input: { onChange, value, ...inputProps },
    ...props
  }) => {
    const [expression, setExpression] = useState(value);
    const handleChange = (e) => setExpression(e.target.value);
    const handleSubmit = (e) => e.keyCode === 13 && onChange(expression);
    return (
      <Fragment>
        <SearchInput
          {...props}
          input={{
            ...inputProps,
            value: expression,
            onChange: handleChange,
            onKeyDown: handleSubmit,
          }}
          value={expression}
          classes={classes}
          onChange={stopPropagation}
          placeholder={translate("resources.explorer.fields.q")}
          helperText={
            <span>
              {translate("resources.explorer.fields.q_help")} |{" "}
              <SearchHelpLink />
            </span>
          }
        />
      </Fragment>
    );
  }
);
export default compose(withTranslate)(SearchExpressionInput);
