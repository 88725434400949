import React from "react";
import { translate, Loading, Error, Query } from "react-admin";
import { Drawer, Typography, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import CloseIcon from "@material-ui/icons/Close";
import { GET_LOGS } from "../addExplorerFeature";
import LogsList from "./LogsList";

const styles = (theme) => ({
  drawerPaper: {
    margin: 0,
    overflow: "hidden",
  },
  root: {
    padding: theme.spacing.unit * 3,
    paddingTop: 50,
    width: 300,
  },
  closeButton: {
    marginRight: -theme.spacing.unit * 2,
  },
  colorButton: {
    margin: theme.spacing.unit * 1,
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  list: {
    [theme.breakpoints.up("xs")]: {
      width: 300,
      overflow: "hidden",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginTop: -30,
    },
  },
});
const LogsView = ({ translate, classes, open, onClose, id, type }) => (
  <Drawer
    classes={{
      paper: classes.drawerPaper,
    }}
    variant="persistent"
    anchor="right"
    open={open}
    onClose={onClose}
  >
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="subheading">
          {translate("resources.explorer.titles.logs")}
        </Typography>
        <IconButton onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.list}>
        {open && (
          <Query resource="explorer" type={GET_LOGS} payload={{ id, type }}>
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return <Error />;
              }
              return <LogsList data={data} />;
            }}
          </Query>
        )}
      </div>
    </div>
  </Drawer>
);
export default compose(translate, withStyles(styles))(LogsView);
