import { connect } from "react-redux";

const withMaps = connect(state => ({
  maps:
    (state.admin &&
      state.admin.resources &&
      state.admin.resources &&
      state.admin.resources.maps &&
      state.admin.resources.maps.data) ||
    {}
}));
export default withMaps;
