import React from "react";
import { MapControl, withLeaflet } from "react-leaflet";
import L from "leaflet";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { translate } from "react-admin";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import Tooltip from "@material-ui/core/Tooltip";

const styles = theme =>
  createStyles({
    select: {
      border: "2px solid rgba(0, 0, 0, 0.2)",
      borderColor: "rgba(0, 0, 0, 0.2)",
      borderRadius: 4,
      backgroundColor: "white",
      color: "black",
      width: 150,
      paddingLeft: theme.spacing.unit * 1
    },
    item: {}
  });

class MapShapeStatus extends MapControl {
  constructor(props) {
    super(props);
    this.state = {
      status: "all"
    };
  }

  componentDidMount() {
    super.componentDidMount();
    ReactDOM.render(this.renderSelect(), this.container);
  }

  onChange(evt) {
    const { onChange } = this.props;
    this.setState({ status: evt.target.value });
    onChange(evt.target.value);
  }

  renderSelect() {
    const { classes, translate } = this.props;
    return (
      <Tooltip
        placement="right"
        title={translate("resources.maps.map.filter.status_help")}
      >
        <Select
          className={classes.select}
          value={this.state.status}
          onChange={this.onChange.bind(this)}
          disableUnderline
        >
          <MenuItem value={"all"}>
            {translate("resources.maps.map.status_all")}
          </MenuItem>
          <MenuItem value={"active"}>
            {translate("resources.maps.map.status_active")}
          </MenuItem>
          <MenuItem value={"disabled"}>
            {translate("resources.maps.map.status_disabled")}
          </MenuItem>
        </Select>
      </Tooltip>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.status !== this.state.status) {
      ReactDOM.render(this.renderSelect(), this.container);
    }
  }

  createLeafletElement(opts) {
    const MapStatus = L.Control.extend({
      onAdd: map => {
        this.container = L.DomUtil.create("div", "info");
        return this.container;
      }
    });
    return new MapStatus({ position: "topleft" });
  }
}

MapShapeStatus.propTypes = {
  translate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default compose(
  connect(_ => ({})),
  translate,
  withLeaflet,
  withStyles(styles)
)(MapShapeStatus);
