import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import ActionDelete from "@material-ui/icons/Delete";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import inflection from "inflection";
import { translate } from "ra-core";
import { Confirm, Button } from "react-admin";
import { crudDeleteMany } from "../../actions/dataActions/crudDeleteMany";
import makeid from "../../utils/makeid";

const sanitizeRestProps = ({
  basePath,
  classes,
  crudDeleteMany,
  filterValues,
  label,
  resource,
  selectedIds,
  ...rest
}) => rest;

const styles = theme =>
  createStyles({
    deleteButton: {
      color: theme.palette.error.main,
      "&:hover": {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        "@media (hover: none)": {
          backgroundColor: "transparent"
        }
      }
    }
  });

class BulkDeleteWithConfirmButton extends Component {
  static propTypes = {
    basePath: PropTypes.string,
    classes: PropTypes.object,
    crudDeleteMany: PropTypes.func.isRequired,
    label: PropTypes.string,
    resource: PropTypes.string.isRequired,
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    icon: PropTypes.element
  };

  static defaultProps = {
    label: "ra.action.delete",
    icon: <ActionDelete />
  };

  state = { isOpen: false, deleting: false };

  handleClick = e => {
    this.setState({ isOpen: true });
    e.stopPropagation();
  };

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  handleDelete = () => {
    const {
      basePath,
      crudDeleteMany,
      resource,
      selectedIds,
      onClick
    } = this.props;

    this.setState({ deleting: true }, () =>
      crudDeleteMany(resource, selectedIds, basePath)
    );

    if (typeof onClick === "function") {
      onClick();
    }
  };

  componentWillUnmount() {
    if (this.state.deleting) {
      const currentLocation = document.location.toString();
      const params = currentLocation.split("#");
      const action = params[1].split("?");
      const qs = new URLSearchParams(action[1]);
      const filter = JSON.parse(qs.get("filter") || "{}");
      document.location.href = `#${action[0]}?filter=${JSON.stringify({
        ...filter
      })}&reload=${makeid(5)}`;
    }
  }

  render() {
    const {
      classes,
      label,
      icon,
      onClick,
      resource,
      selectedIds,
      translate,
      ...rest
    } = this.props;
    return (
      <Fragment>
        <Button
          onClick={this.handleClick}
          label={label}
          className={classes.deleteButton}
          {...sanitizeRestProps(rest)}
        >
          {icon}
        </Button>
        <Confirm
          isOpen={this.state.isOpen}
          title="ra.message.bulk_delete_title"
          content="ra.message.bulk_delete_content"
          translateOptions={{
            smart_count: selectedIds.length,
            name: inflection.humanize(
              translate(`resources.${resource}.name`, {
                smart_count: selectedIds.length,
                _: inflection.inflect(resource, selectedIds.length)
              }),
              true
            )
          }}
          onConfirm={this.handleDelete}
          onClose={this.handleDialogClose}
        />
      </Fragment>
    );
  }
}

const EnhancedBulkDeleteWithConfirmButton = compose(
  connect(
    undefined,
    { crudDeleteMany }
  ),
  translate,
  withStyles(styles)
)(BulkDeleteWithConfirmButton);

export default EnhancedBulkDeleteWithConfirmButton;
