import React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";

const GroupCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="code" />
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);
export default GroupCreate;
