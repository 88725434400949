import React, { Component } from "react";
import {
  translate,
  withDataProvider,
  showNotification,
  Notification,
} from "react-admin";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { Title } from "react-admin";
import Avatar from "@material-ui/core/Avatar";
import LockIcon from "@material-ui/icons/Lock";
import { TextField, Button } from "@material-ui/core";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { push } from "react-router-redux";

const styles = (theme) =>
  createStyles({
    root: {
      display: "flex",
      minHeight: "100vh",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "flex-start",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundColor: "GhostWhite",
    },
    form: {
      minWidth: 300,
      marginTop: "6em",
    },
    header: {
      margin: "1em",
      display: "flex",
      justifyContent: "center",
    },
    buttonsContainer: {
      flex: 1,
      flexDirection: "column",
      margin: theme.spacing.unit,
    },
    button: {
      display: "block",
      clear: "both",
      width: "100%",
      marginBottom: theme.spacing.unit,
    },
    link: {
      width: "auto",
      float: "left",
      alignSelf: "flex-start",
      marginLeft: 0,
    },
    icon: {
      marginRight: theme.spacing.unit,
    },
  });

class PasswordResetPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      account: "",
      error: null,
      loading: false,
    };
  }

  handleSubmit(evt) {
    evt.preventDefault();
    const { dataProvider, showNotification, push } = this.props;
    this.setState({ loading: true }, () => {
      dataProvider("CREATE", "reset-password", {
        account: this.state.account,
      }).then((response) => {
        if (!response.data.success) {
          const keys = Object.keys(response.data.errors);
          if (keys.some((key) => key === "account")) {
            const error = response.data.errors["account"];
            this.setState({ error, loading: false });
          }
        } else {
          this.setState({ loading: false, error: null }, () => {
            showNotification("app.password_changed", "info");
            push("/login");
          });
        }
      });
    });
  }

  render() {
    const { classes, translate } = this.props;
    return (
      <div className={classes.root}>
        <form className={classes.form} onSubmit={this.handleSubmit.bind(this)}>
          <Card>
            <Title title="Reset password" />
            <div className={classes.header}>
              <Avatar>
                <LockIcon />
              </Avatar>
            </div>
            <CardContent>
              <TextField
                autoFocus
                label="Account"
                fullWidth
                error={this.state.error !== null}
                helperText={this.state.error}
                onChange={(evt) => this.setState({ account: evt.target.value })}
              />
            </CardContent>
            <CardActions className={classes.buttonsContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                type="submit"
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <CircularProgress
                    className={classes.icon}
                    size={18}
                    thickness={2}
                  />
                )}
                {translate("ra.auth.reset_password")}
              </Button>

              <Button
                className={classes.link}
                href="/dashboard/#/login"
                size="small"
                color="primary"
              >
                &larr;
                {translate("ra.auth.back_to_login")}
              </Button>
            </CardActions>
          </Card>
        </form>
        <Notification />
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withDataProvider,
  connect(null, { showNotification, push }),
  translate
)(PasswordResetPage);
