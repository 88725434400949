import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import FolderIcon from "@material-ui/icons/Folder";
import HelpIcon from "@material-ui/icons/Help";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import React from "react";

const IconField = ({ record }) => {
  if (record.id === "prev") {
    return <ArrowUpwardIcon style={{ marginTop: 10, marginBottom: 10 }} />;
  } else if (record.type === "container") {
    return <FolderIcon />;
  } else if (record.type === "asset") {
    return <InsertDriveFileIcon />;
  } else {
    return <HelpIcon />;
  }
};
export default IconField;
