import React from "react";
import {
  Filter,
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput
} from "react-admin";
import types from "./types";

const Filters = props => (
  <Filter {...props}>
    <TextInput source="q" alwaysOn />
    <SelectInput source="type" choices={types} />
    <ReferenceInput source="archive_id" reference="archives">
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);
export default Filters;
