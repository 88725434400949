import React, { Fragment } from "react";
import { TextInput, DateInput, SelectInput } from "react-admin";
import types from "../columns/types";
import { DateTimeInput } from "../../components/inputs/DateTimeInput";
import { translate } from "ra-core";
import expressions, { isValuelessExpression } from "../columns/expressions";
import typeExpressionsMap from "../columns/typeExpressionsMap";
import { connect } from "react-redux";
const FilterInput = ({
  translate,
  query,
  formFilters,
  filter,
  parse,
  format,
  dispatch,
  ...props
}) => {
  let mappedColumn = query.columns.find(c => c.name === filter.column);
  let input;
  let validExpressions = typeExpressionsMap[mappedColumn.type] || [];

  switch (mappedColumn.type) {
    case types.DATE:
      input = <DateInput {...props} label={filter.alias} />;
      break;
    case types.DATETIME:
      input = <DateTimeInput {...props} label={filter.alias} />;
      break;
    default:
      input = <TextInput {...props} label={filter.alias} />;
      break;
  }
  const isValueless = isValuelessExpression(
    formFilters[`${filter.qs_param}_expr`] || filter.expression
  );

  return (
    <Fragment>
      <SelectInput
        disabled={filter.expression !== expressions.CHOSEN_BY_USER}
        defaultValue={filter.expression}
        source={`${filter.qs_param}_expr`}
        style={{ width: "auto", minWidth: 30, paddingRight: 20 }}
        choices={validExpressions.map(expr => ({
          id: expr,
          name: translate(
            `resources.queries.fields.filters.expressions.${expr}`
          )
        }))}
        label={
          !isValueless
            ? "resources.queries.fields.filters.expression"
            : filter.alias
        }
      />
      {!isValueless && input}
    </Fragment>
  );
};
export default connect(state => ({
  formFilters:
    (state.form && state.form.filterForm && state.form.filterForm.values) || {}
}))(translate(FilterInput));
