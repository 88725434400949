import React from "react";
import {
  Filter,
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput
} from "react-admin";
import statuses from "./statuses";

const Filters = props => (
  <Filter {...props}>
    <TextInput source="q" alwaysOn />
    <SelectInput source="status" choices={statuses} />
    <ReferenceInput source="user_id" reference="users">
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
  </Filter>
);

export default Filters;
