import { DateField, TextField } from "react-admin";
import DateTime from "./formatters/DateTime";
import FileSize from "./formatters/FileSize";

export default {
  Text: TextField,
  Date: DateField,
  DateTime: DateTime,
  FileSize: FileSize
};
