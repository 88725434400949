import React, { Component, Fragment } from "react";
import { Rectangle, Polygon, Tooltip, Marker } from "react-leaflet";
import { icon } from "leaflet";
import MapFilter from "./MapFilter";

import PropTypes from "prop-types";
import "leaflet-draw/dist/leaflet.draw.css";
import { connect } from "react-redux";
import { compose } from "recompose";
import { push } from "react-router-redux";

const markerIcon = icon({
  iconUrl: "https://image.flaticon.com/icons/svg/1673/1673264.svg",
  iconRetinaUrl: "https://image.flaticon.com/icons/svg/1673/1673264.svg",
  iconSize: [42, 42],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maps: props.maps,
      zoom: props.zoom,
      selected: null
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const { maps } = this.props;
      this.setState({
        zoom: this.props.zoom,
        maps
      });
    }
  }

  onMarkerClick(marker) {
    const { onMarkerClick } = this.props;
    const center = [marker.latlng.lat, marker.latlng.lng];
    onMarkerClick(center, marker.target.options.zoom);
  }

  renderMaps() {
    return this.state.maps.map(m => (
      <Marker
        key={m.id}
        position={[m.lat, m.lng]}
        zoom={m.zoom}
        icon={markerIcon}
        onClick={this.onMarkerClick.bind(this)}
      >
        <Tooltip>{m.name}</Tooltip>
      </Marker>
    ));
  }

  onShapeCancel() {
    this.props.onShapeClick(null);
  }

  onShapeClick(shape) {
    this.props.onShapeClick(shape);
  }

  renderShapes() {
    const shapes = this.state.maps.reduce((shapes, map) => {
      return shapes.concat(map.shapes.filter(s => s.status === "active"));
    }, []);

    return shapes.map(s => {
      if (s.type === "rectangle") {
        return (
          <Rectangle
            key={s.id}
            bounds={s.coords}
            id={s.id}
            code={s.code}
            color={s.color}
            onClick={() => this.onShapeClick(s)}
          >
            <Tooltip>{s.name}</Tooltip>
          </Rectangle>
        );
      } else if (s.type === "polygon") {
        return (
          <Polygon
            key={s.id}
            positions={s.coords}
            id={s.id}
            color={s.color}
            code={s.code}
            onClick={() => this.onShapeClick(s)}
          >
            <Tooltip>{s.name}</Tooltip>
          </Polygon>
        );
      } else {
        return null;
      }
    });
  }

  onChangeMap(id) {
    const { onChangeMap } = this.props;
    const map = this.state.maps.find(m => m.id === id);
    if (!map) {
      return;
    }
    const center = [map.lat, map.lng];
    onChangeMap(center, map.zoom);
  }

  render() {
    return (
      <Fragment>
        {this.state.zoom >= 16 && this.renderShapes()}
        {this.state.zoom < 16 && this.renderMaps()}
        <MapFilter
          maps={this.state.maps}
          onChange={mapId => this.onChangeMap(mapId)}
        />
      </Fragment>
    );
  }
}

Content.propTypes = {
  maps: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired,
  onMarkerClick: PropTypes.func.isRequired,
  onChangeMap: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired
};

export default compose(connect(_ => ({ push })))(Content);
