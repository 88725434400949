import { Filter, withTranslate } from "react-admin";
import React, { useEffect, useState } from "react";

import Field from "../forms/input";
import FormSelectInput from "./input/FormSelectInput";
import GroupsSelectInput from "./input/GroupsSelectInput";
import SearchExpressionInput from "./input/SearchExpressionInput";
import ViewKindInput from "./input/ViewKindInput";
import { change } from "redux-form";
import { connect } from "react-redux";
import useDebounce from "./useDebounce";
import { withStyles } from "@material-ui/core";

const filters = (forms) =>
  forms.reduce(
    (filters, form) =>
      filters.concat(
        form.props.map((prop) => ({
          ...prop,
          name: `p_${prop.id}`,
          label: `${form.name}/${prop.name}`,
        }))
      ),
    []
  );

const Filterable = connect()(
  ({ prop, dispatch, resource, source, translate, meta, ...state }) => {
    const [value, setValue] = useState("");
    const debouncedValue = useDebounce(value, 500);
    useEffect(() => {
      dispatch(change(meta.form, prop.name, debouncedValue));
    }, [debouncedValue, dispatch, meta.form, prop.name]);
    return (
      <Field
        source={prop.name}
        label={prop.label}
        resettable={true}
        prop={{ ...prop, value }}
        onChange={(v) => {
          setValue(v);
        }}
      />
    );
  }
);

const Filters = withStyles((theme) => ({
  form: {
    paddingBottom: theme.spacing.unit * 2,
  },
}))(({ classes, translate, forms, dispatch, ...props }) => (
  <Filter {...props} classes={classes}>
    <SearchExpressionInput source="q" alwaysOn />
    <ViewKindInput source="view_kind" />
    <GroupsSelectInput source="group" />
    <FormSelectInput source="form" />
    {filters(forms).map((prop, idx) => (
      <Filterable
        source={prop.name}
        label={prop.label}
        key={idx}
        prop={{ ...prop, value: props.filterValues[prop.name] || "" }}
      />
    ))}
  </Filter>
));
export default withTranslate(Filters);
