import React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";
import MapInput from "./input/MapInput";

const MapCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list" undoable={true} submitOnEnter={false}>
      <TextInput
        source="name"
        options={{ fullWidth: true }}
        style={{ display: "block", clear: "both" }}
      />
      <MapInput source="shapes" />
    </SimpleForm>
  </Create>
);

export default MapCreate;
