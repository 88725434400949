import { CREATE } from "react-admin";
export const PASSWORD_CHANGE = "PASSWORD_CHANGE";
export const change = (data = {}, basePath, redirectTo) => ({
  type: PASSWORD_CHANGE,
  payload: { data },
  meta: {
    resource: "user-password-change",
    fetch: CREATE,
    onSuccess: {
      notification: {
        body: "app.password_changed",
        level: "info"
      },
      basePath,
      redirectTo: redirectTo || "/"
    }
  }
});
