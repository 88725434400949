import React from "react";
import filesize from "../../utils/filesize";
import { get } from "lodash";

const SizeField = ({ record, source }) => (
  <span>
    {get(record, source, null) !== null && filesize(get(record, source))}
  </span>
);
SizeField.propTypes = {};

export default SizeField;
