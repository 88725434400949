import React from "react";
import {
  TabbedForm,
  FormTab,
  TextInput,
  NumberInput,
  SelectInput,
  ReferenceArrayInput,
  FormDataConsumer,
  CheckboxGroupInput
} from "react-admin";
import QueryTextInput from "./inputs/QueryTextInput";
import sortDirections from "./columns/sortDirections";
import ColumnsInput from "./inputs/ColumnsInput";
import FiltersInput from "./inputs/FiltersInput";

const FormatGroups = groups => (groups ? groups.map(g => g.id) : []);
const ParseGroups = groups => (groups ? groups.map(id => ({ id })) : []);

const Form = props => (
  <TabbedForm {...props}>
    <FormTab label="resources.queries.tabs.general">
      <TextInput source="resource" />
      <TextInput source="name" />
      <ReferenceArrayInput
        format={FormatGroups}
        parse={ParseGroups}
        source="groups"
        reference="groups"
      >
        <CheckboxGroupInput />
      </ReferenceArrayInput>
    </FormTab>
    <FormTab label="resources.queries.tabs.query">
      <FormDataConsumer>
        {({ formData, scopedFormData, ...props }) => (
          <QueryTextInput
            {...props}
            source="query_text"
            columns={formData && formData !== null ? formData.columns : []}
          />
        )}
      </FormDataConsumer>

      <NumberInput source="per_page" />
      <FormDataConsumer>
        {({ formData, scopedFormData, ...props }) => (
          <SelectInput
            {...props}
            source="sort"
            choices={
              formData && formData.columns
                ? formData.columns.map(column => ({
                    id: column.name,
                    name: column.alias || column.name
                  }))
                : []
            }
          />
        )}
      </FormDataConsumer>
      <SelectInput
        source="direction"
        choices={sortDirections.map(dir => ({ id: dir, name: dir }))}
      />
    </FormTab>
    <FormTab label="resources.queries.tabs.columns">
      <ColumnsInput source="columns" />
    </FormTab>
    <FormTab label="resources.queries.tabs.filters">
      <FiltersInput source="filters" />
    </FormTab>
  </TabbedForm>
);
export default Form;
