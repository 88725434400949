import React from "react";
import {
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  SelectInput,
  BooleanInput,
  translate
} from "react-admin";
import compose from "recompose/compose";
import Types from "./input/Types";

const style = {
  display: "inline",
  float: "left",
  marginRight: "20px",
  width: "auto"
};

const Props = ({ translate, ...props }) => (
  <ArrayInput {...props} style={{ width: "100%" }}>
    <SimpleFormIterator>
      <TextInput
        source="name"
        label="resources.forms.fields.props.fields.name"
        style={style}
      />
      <SelectInput
        source="type"
        label="resources.forms.fields.props.fields.type"
        choices={Types.map(type => ({
          id: type,
          name: translate(`resources.forms.fields.props.types.${type}`)
        }))}
        optionText="name"
        optionValue="id"
      />
      <ArrayInput
        style={{ clear: "both", width: "100%" }}
        source="config"
        label="resources.forms.fields.props.config"
      >
        <SimpleFormIterator>
          <TextInput
            key="name"
            label="resources.forms.fields.props.fields.name"
            style={style}
            source="name"
          />
          <TextInput
            key="value"
            label="resources.forms.fields.props.fields.value"
            style={style}
            source="value"
          />
        </SimpleFormIterator>
      </ArrayInput>
      <BooleanInput
        source="is_required"
        label="resources.forms.fields.props.fields.is_required"
        style={style}
      />
    </SimpleFormIterator>
  </ArrayInput>
);

export default compose(translate)(Props);
