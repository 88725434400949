import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { translate } from "react-admin";
import compose from "recompose/compose";
import SaveIcon from "@material-ui/icons/Save";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";

const styles = theme =>
  createStyles({
    root: {
      padding: theme.spacing.unit * 3,
      paddingTop: 50
    },
    closeButton: {
      marginRight: -theme.spacing.unit * 2
    },
    colorButton: {
      margin: theme.spacing.unit * 1
    },
    title: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    form: {
      [theme.breakpoints.up("xs")]: {
        width: 400,
        overflow: "hidden"
      },
      [theme.breakpoints.down("xs")]: {
        width: "100vw",
        marginTop: -30
      }
    },
    field: {
      marginTop: theme.spacing.unit * 3,
      width: "100%"
    },
    buttonsContainer: {
      position: "fixed",
      bottom: 0,
      width: "100%",
      paddingLeft: theme.spacing.unit * 3,
      paddingBottom: 12,
      paddingTop: 12,
      backgroundColor: "#f5f5f5",
      marginLeft: -theme.spacing.unit * 3
    }
  });

class MapShapeInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.record.name,
      code: props.record.code,
      status: props.record.status || "active",
      color: props.record.color || "#3388FF",
      error: false
    };
  }

  componentDidUpdate(prevProps) {
    const prevRecord = prevProps.record;
    const currentRecord = this.props.record;
    if (prevRecord.code !== currentRecord.code) {
      this.setState({
        name: currentRecord.name,
        code: currentRecord.code,
        status: currentRecord.status,
        color: currentRecord.color,
        error: false
      });
    }
  }

  onSubmit() {
    const { onSubmit } = this.props;
    if (!this.state.name || this.state.name === "") {
      this.setState({ error: true });
    } else {
      this.setState({ error: false });
      onSubmit(this.state);
    }
  }

  render() {
    const { classes, onCancel, translate } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <Typography variant="subheading">
            {translate("resources.maps.fields.shapes.name")}
          </Typography>
          <IconButton onClick={onCancel} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className={classes.form}>
          <TextField
            autoFocus={true}
            value={this.state.name}
            error={this.state.error}
            className={classes.field}
            label={translate("resources.maps.fields.name")}
            onChange={evt => this.setState({ name: evt.target.value })}
          />

          <Tooltip
            placement={"left"}
            title={translate("resources.maps.map.status_help")}
          >
            <FormControl className={classes.field}>
              <InputLabel htmlFor={"status"}>
                {translate("resources.maps.map.status")}
              </InputLabel>
              <Select
                value={this.state.status}
                inputProps={{
                  id: "status"
                }}
                onChange={evt => this.setState({ status: evt.target.value })}
              >
                <MenuItem value={"active"}>
                  {translate("resources.maps.map.status_active")}
                </MenuItem>
                <MenuItem value={"disabled"}>
                  {translate("resources.maps.map.status_disabled")}
                </MenuItem>
              </Select>
            </FormControl>
          </Tooltip>
          <FormControl className={classes.field}>
            <TextField
              name="color"
              type="color"
              fullWidth
              label={translate("resources.maps.map.color")}
              value={this.state.color}
              onChange={e => this.setState({ color: e.target.value })}
            />
          </FormControl>
          <div className={classes.buttonsContainer}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={this.onSubmit.bind(this)}
            >
              <SaveIcon />
              &nbsp;
              {translate("resources.maps.map.edit.toolbar.save_title")}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(translate, withStyles(styles))(MapShapeInput);
