import React from "react";
import {
  Confirm,
  translate,
  refreshView,
  withDataProvider,
  showNotification
} from "react-admin";
import { connect } from "react-redux";
import { compose } from "recompose";
import FileSystemTypes from "./FileSystemTypes";
import { MOVE_ASSET, MOVE_CONTAINER } from "../addExplorerFeature";
import { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { createStyles, withStyles } from "@material-ui/core";
import clsx from "clsx";
import { moveContainer, moveAsset } from "./FileSystemActions";
import makeid from "../utils/makeid";

const styles = theme =>
  createStyles({
    overDrop: {
      textDecoration: "underline"
    },
    cantDrop: {
      cursor: "not-allowed"
    },
    dragging: {
      cursor: "grabbing",
      backgroundColor: "#d3d3d3"
    },
    draggable: {
      border: "1px solid transparent",

      "&:hover": {
        cursor: "grab",
        border: "1px dotted #d3d3d3",
        borderRadius: 5
      }
    }
  });

const DragAndDrop = ({
  drag = true,
  drop = true,
  node,
  translate,
  classes,
  children,
  dataProvider,
  showNotification
}) => {
  const [move, setMove] = useState({
    confirm: false,
    item: {
      type: FileSystemTypes.UNKNOW,
      name: null
    }
  });
  const cancelMove = () =>
    setMove({
      confirm: false,
      item: {
        type: FileSystemTypes.UNKNOW,
        name: null
      }
    });
  const showMove = item => setMove({ confirm: true, item });
  const confirmMove = (source, destination) => {
    if (source.type === FileSystemTypes.ASSET) {
      dataProvider(MOVE_ASSET, "explorer", {
        assetId: source.record_id,
        destinationContainerId: destination.record_id
      }).then(({ data }) => {
        showNotification(data.message, data.success !== true ? "warning" : "");
        setMove({
          confirm: false,
          item: { type: FileSystemTypes.UNKNOW, name: null }
        });
        if (data.success === true) {
          moveAsset(source, destination);
        }
      });
    } else if (source.type === FileSystemTypes.CONTAINER) {
      dataProvider(MOVE_CONTAINER, "explorer", {
        sourceContainerId: source.record_id,
        destinationContainerId: destination.record_id
      }).then(({ data }) => {
        showNotification(data.message, data.success !== true ? "warning" : "");
        setMove({
          confirm: false,
          item: { type: FileSystemTypes.UNKNOW, name: null }
        });
        if (data.success === true) {
          moveContainer(source, destination);
        }
      });
    }
  };

  const [{ opacity, isDragging }, dragRef] = useDrag({
    item: {
      type: FileSystemTypes.UNKNOW,
      ...node
    },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.9 : 1,
      isDragging: monitor.isDragging()
    })
  });
  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: [
      FileSystemTypes.UNKNOW,
      FileSystemTypes.CONTAINER,
      FileSystemTypes.ASSET
    ],
    drop: item => showMove(item),
    canDrop: (item, monitor) => {
      if (node.type === FileSystemTypes.ASSET) {
        return false;
      }
      if (node.id === item.id) {
        return false;
      }
      if (!item.perms || !item.perms.can_edit) {
        return false;
      }
      if (!node || node === null || node.record_id === null) {
        return false;
      }
      if (item.container_id === node.record_id) {
        return false;
      }

      const destinationPath = node.path
        .substr(1, item.path.length - 2)
        .split("/");

      if (destinationPath.indexOf(item.record_id.toString()) !== -1) {
        return false;
      }

      return true;
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop()
    })
  });

  return (
    <span
      ref={node => {
        if (drag) dragRef(node);
        if (drop) dropRef(node);
      }}
      style={{ opacity }}
    >
      <Confirm
        key={makeid(5)}
        isOpen={move.confirm}
        dismissable={false}
        title="resources.explorer.messages.confirm_move"
        content={`resources.explorer.messages.confirm_${move.item.type}_move`}
        translateOptions={{
          source: move.item.name,
          destination:
            node.id === "prev" && node.location
              ? node.location.length > 1
                ? node.location[node.location.length - 2].name
                : translate("resources.explorer.breadcrumbs.root")
              : node.name
        }}
        onConfirm={() => confirmMove(move.item, node)}
        onClose={() => cancelMove()}
      />
      {React.Children.map(children, child =>
        React.cloneElement(child, {
          node,
          className: clsx({
            [classes.overDrop]: isOver,
            [classes.cantDrop]: canDrop,
            [classes.dragging]: isDragging,
            [classes.draggable]: drag
          })
        })
      )}
    </span>
  );
};

export default compose(
  withDataProvider,
  withStyles(styles),
  translate,
  connect(null, {
    refreshView,
    showNotification
  })
)(DragAndDrop);
