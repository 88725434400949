import React from "react";
import { translate } from "react-admin";
import { createStyles, withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import compose from "recompose/compose";
import { Scrollbars } from "react-custom-scrollbars";
import moment from "moment";
const styles = (theme) =>
  createStyles({
    root: {
      width: "100%",
      margin: 0,
      backgroundColor: theme.palette.background.paper,
    },
    nav: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
    modifiedField: {
      fontSize: 14,
      color: theme.palette.secondary,
      display: "inline-block",
    },
    emptyBox: {
      textAlign: "center",
    },
    emptyBoxIcon: {
      fontSize: 100,
    },
    listItem: {
      marginLeft: 0,
      paddingLeft: 0,
    },
  });

const LogsList = withStyles(styles)(({ classes, data, translate }) => {
  if (!data || data.length === 0) {
    return (
      <div className={classes.emptyBox}>
        <WarningIcon className={classes.emptyBoxIcon} />
        <Typography variant="subheading">
          {translate("resources.explorer.messages.no_logs")}
        </Typography>
      </div>
    );
  }
  return (
    <Scrollbars
      autoHeight
      autoHeightMax={window.outerHeight - 220}
      autoHide={true}
    >
      <List>
        {data.map((logEntry, i) => (
          <ListItem key={i} className={classes.listItem}>
            <Avatar>{logEntry.user.substring(0, 2)}</Avatar>
            <ListItemText
              primary={logEntry.user}
              secondary={
                <>
                  <Typography variant="body1" component="span">
                    {translate(`resources.explorer.logs.${logEntry.type}`)}
                  </Typography>
                  <Typography variant="caption" component="span">
                    {moment(logEntry.created).format(
                      translate("app.date_format.long")
                    )}
                  </Typography>
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </Scrollbars>
  );
});

export default compose(translate)(LogsList);
