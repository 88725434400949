import {
  Button,
  Responsive,
  showNotification,
  translate,
  withDataProvider,
} from "react-admin";
import React, { Fragment, useState } from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";

import ArchiveIcon from "@material-ui/icons/Archive";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dropzone from "react-dropzone";
import LinearProgress from "@material-ui/core/LinearProgress";
import MuiButton from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { UPLOAD_ARCHIVE } from "../../addArchiveFeature";
import classnames from "classnames";
import compose from "recompose/compose";
import { connect } from "react-redux";
import onlyUpdateForKeys from "recompose/onlyUpdateForKeys";

const styles = (theme) =>
  createStyles({
    floating: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      margin: 0,
      top: "auto",
      right: 20,
      bottom: 60,
      left: "auto",
      position: "fixed",
      zIndex: 1000,
    },
    floatingLink: {
      color: "inherit",
    },
    loader: {},
    dropZone: {
      background: theme.palette.background.default,
      cursor: "pointer",
      padding: "1rem",
      textAlign: "center",
      color: theme.palette.text.hint,
    },
  });

const ImportButton = ({
  dispatch,
  basePath = "explorer",
  dataProvider,
  filterValues,
  className,
  classes = {},
  translate,
  label = "Import",
  resource = "explorer",
  currentSort,
  showNotification,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [load, setLoad] = useState(false);
  const openDialog = () => {
    setOpen(true);
    setFile(null);
    setLoad(false);
  };
  const closeDialg = () => setOpen(false);
  const uploadArchive = () => {
    setLoad(true);
    dataProvider(UPLOAD_ARCHIVE, "archives", {
      container_id: filterValues.id || null,
      archive: file,
    }).then(({ success, data: { message } }) => {
      showNotification(message, success ? "info" : "warning");
      closeDialg();
    });
  };
  const onDrop = (_, files) => (files.length === 1 ? setFile(files[0]) : null);
  return (
    <Fragment>
      <Responsive
        small={
          <MuiButton
            variant="text"
            color="primary"
            className={classnames(classes.floating, className)}
            aria-label={label && translate(label)}
            onClick={openDialog}
            {...rest}
          >
            <ArchiveIcon />
          </MuiButton>
        }
        medium={
          <Button
            className={className}
            label={label}
            {...rest}
            onClick={openDialog}
          >
            <ArchiveIcon />
          </Button>
        }
      />
      <Dialog open={open}>
        <DialogTitle>
          {translate("resources.explorer.titles.import")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {translate("resources.explorer.messages.import")}
          </DialogContentText>
          <Dropzone
            onDrop={onDrop}
            className={classes.dropZone}
            disableClick={false}
            accept={"*.zip,application/zip,application/octet-stream"}
            multiple={false}
          >
            <p>{translate("resources.explorer.messages.import_drop_zone")}</p>
            {file !== null && (
              <Typography variant="body2">{file.name}</Typography>
            )}
          </Dropzone>
          {load && <LinearProgress className={classes.loader} />}
          {load && <br />}
          {load && (
            <DialogContentText>
              {translate("resources.explorer.messages.import_message")}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <MuiButton
            disabled={file === null || load === true}
            onClick={uploadArchive}
            color="primary"
          >
            {translate("resources.explorer.actions.import")}
          </MuiButton>
          <MuiButton
            onClick={closeDialg}
            color="secondary"
            disabled={load === true}
          >
            {translate("ra.action.cancel")}
          </MuiButton>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
ImportButton.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  label: PropTypes.string,
  size: PropTypes.string,
  translate: PropTypes.func.isRequired,
  icon: PropTypes.element,
};

const enhance = compose(
  translate,
  withDataProvider,
  connect(null, {
    showNotification,
  }),
  onlyUpdateForKeys([
    "basePath",
    "label",
    "translate",
    "filterValues",
    "currentSort",
  ]),
  withStyles(styles)
);

export default enhance(ImportButton);
