import {
  TYPE_CHECKBOX,
  TYPE_DATE,
  TYPE_DATETIME,
  TYPE_NUMBER,
  TYPE_SELECT,
  TYPE_SELECT_MULTI,
  TYPE_TEXT,
  TYPE_TEXTAREA,
} from "../forms/input/Types";

import italianMessages from "ra-language-italian";

const messages = {
  ...italianMessages,

  "ra.auth.login": "Login",
  "ra.auth.signup": "Signup",
  "ra.auth.forgot_password": "Password dimenticata?",
  "ra.auth.back_to_login": "Torna al login",
  "ra.auth.reset_password": "Reset",

  menu: {
    groups: {
      dashboard: "Cruscotto",
      reporting: "Reportistica",
      admin: "Amministrazione",
    },
    items: {
      dashboard: "Dashboard",
      archives: "Archivi",
      explorer: "Esplora",
      recycler: "Cestino",
      queries: "Reports",
      users: "Utenti",
      roles: "Ruoli",
      groups: "Gruppi",
      forms: "Forms",
      maps: "Mappe",
      map: "Mappa",
      profile: "Profilo",
      "login-images": "Sfondi login",
      "password-change": "Cambio password",
    },
  },
  app: {
    no_records: "Nessun record restitutio",
    welcome: "Bevenuto %{username}",
    date_format: {
      long: "DD/MM/YYYY HH:mm:ss",
      short: "DD/MM/YYYY",
    },
    my_profile: "Il mio profilo",
    password_changed: [
      "Password modificata con successo,",
      "controlla la tua email!",
    ].join(" "),
    messages: {
      records_found:
        "Sono stati restituiti %{results} %{resource} su %{total} in base ai filtri di ricerca inseriti.",
    },
  },
  dashboard: {
    used_space: "Spazio Utilizzato",
    containers: "Cartelle",
    assets: "Files",
    welcome: {
      name: "Pagina di cortesia",
      title: "Oops!",
      message_1:
        "Benvenuto all'interno della piattaforma, abbiamo notato che sei un nuovo utente e non sei in possesso dei permessi necessari per poter visualizzare i dati e/o funzioni. Se ritieni di essere in possesso dei privilegi necessari per visionare i contenuti disponibili inoltra una email a info@its.engineering per richiedere supporto in merito alla corretta attivazione del tuo account.",
      message_2:
        "Per consentire ai tecnici di poter identificare correttamente le tue credenziali, all'interno della richiesta di supporto che inoltrerai, dovrai specificare i seguenti dati necessari per identificare correttamente le tue credenziali di accesso:",
    },
  },
  resources: {
    explorer: {
      name: "Explorer",
      navigator: "Naviga",
      fields: {
        type: "Tipo",
        name: "Nome",
        size: "Dimensione",
        notes: "Note",
        is_pinned: "Mostra in bacheca",
        is_pinned_help: [
          "Consente di mostrare il campo note nella funzione",
          "Explorer direttamente aprendo la cartella.",
        ].join(" "),
        is_priority: "Importante",
        is_priority_help: [
          "Il documento sarà evidenziato",
          "nell'elenco per una sua facile identificazione",
        ].join(" "),
        map: "Mappa",
        forms: "Catalogo",
        propagate_perms: "Propaga permessi",
        perms: {
          name: "Permessi",
        },
        user_perms: {
          name: "Permessi Utente",
        },
        props: {
          name: "Proprietà",
          fields: {
            name: "Nome",
            value: "Valore",
          },
        },
        groups: "Gruppi",
        modified: "Ultima modifica",
        deleted: "Data cancellazione",
        q: "Chiave di ricerca",
        q_help: "Premi invio per avviare la ricerca",
        view_kind: "Visualizza",
      },
      actions: {
        add_container: "Nuova Cartella",
        add_asset: "Carica File",
        download: "Scarica",
        import: "Importa",
        save: "Salva",
        edit: "Modifica",
        restore: "Ripristina",
        view_logs: "Visualizza Logs",
        checkout: "Check Out",
        checkin: "Check In",
      },
      titles: {
        import: "Importa da archivio compresso",
        logs: "Logs",
        checkout: "Check-Out",
        checkin: "Check-In",
        clone: "Clona cartella",
      },
      messages: {
        checkout: [
          "Il check-out ti consente di avere un accesso esclusivo a questo file e di poterlo eventualmente sostituire.",
          "Tutti potranno continuare a scaricare una copia del file ma nessuno potrà modificarlo.",
          "Quando avrai completato le tue operazioni di modifica potrai ricaricare il file e sbloccarlo,",
          "consentendo a chiunque altro di poterci lavorare, esegundo l'operazione di check in.",
          "Sei sicuro di voler continuare?",
        ].join(" "),
        checkout_executed: "Operazione di check-out eseguita correttamente.",
        checkin: "Vuoi concludere le tue modifiche e rilasciare il file?",
        checkin_executed: "Operazione di check-in eseguita correttamente.",
        checked_out: [
          "Hai accesso esclusivo a questo documento, completa le tue modifiche ed esegui il check-in",
          "per rendere il documento disponibile in modifica anche agli altri.",
        ].join(" "),
        checked_out_short: "Accesso esclusivo in modifica",
        checked_out_from: [
          "Questo documento è bloccato da %{name} %{surname} (%{email}), ",
          "pertanto non è possibile apportare modifiche di alcun tipo.",
        ].join(" "),
        checked_out_from_short: ["File bloccato dall'utente %{username}"].join(
          " "
        ),
        no_data: "Nessuna cartella/file presente.",
        no_data_for_search_expression: [
          "Nessun risultato trovato per l'espressione specificata: <b>%{expression}</b>.",
          "Prova ad utilizzare termini di ricerca differenti o consulta la guida per apprendere ulteriori nozioni sulle funzioni di ricerca del software.",
        ].join("<br />"),
        no_logs: "Nessun messaggio di log presente.",
        loading: "Caricamentob...",
        confirm_move: "Conferma spostamento",
        confirm_container_move:
          "Sicuro di voler spostare la cartella %{source} all'interno di %{destination}?",
        confirm_asset_move:
          "Sicuro di voler spostare il file %{source} all'interno della cartella %{destination}?",
        import: [
          "Seleziona un archivio ZIP da cui importare automaticamente cartelle ",
          "e file. L'archivio sarà inserito in una coda di caricamento e ",
          "importato autonomamente dal sistema. Per visionare lo stato di avanzamento ",
          "del caricamento puoi sempre consultare la sezione Archivi.",
        ].join(" "),
        import_drop_zone: "Clicca o trascina qui l'archivio da caricare.",
        import_message:
          "Attendere prego, il caricamento potrebbe richiedere diversi minuti...",
        importing_archives: [
          "Si comunica che è in corso il caricamento di %{count} archivio/i",
          "all'interno di questa cartella.",
        ].join(" "),
        clone: [
          "Confermando l'operazione la struttura della cartella selezionata sarà duplicata e le sarà",
          "assegnato un nome temporaneo che dovrà essere successivamente modificato.",
          "Sicuro di voler procedere?",
        ].join(" "),
        clone_executed: "Cartella clonata!",
        priority: "Prioritario",
      },
      breadcrumbs: {
        position: "Posizione",
        root: "Cartella Principale",
      },
      logs: {
        open: "Apertura",
        open_logs: "Visualizzazione logs",
        create: "Creazione",
        update: "Aggiornamento dati",
        delete: "Cancellato",
        restore: "Ripristinato",
        erase: "Cancellato definitivamente",
        file_replace: "File sostituito",
        download: "Scaricato",
        move: "Spostato",
        imported: "Importato",
        checkin: "Check In",
        checkout: "Check Out",
      },
      view_kind: {
        all: "Tutti i file",
        mine: "I miei file",
        checked_out: "File bloccati (checked out)",
      },
      search_help: {
        link: "Hai bisogno di aiuto?",
        read: "Consulta la guida",
        title: "Come ricercare contenuti",
        paragraph1: [
          "La ricerca può avvenire sfruttando diversi caratteri speciali utili a restringere e/o allargare il numero dei risultati.",
          "Di seguito sono descritti i caratteri speciali che è possibile utilizzare:",
        ].join(" "),
        paragraph2: [
          "Gli operatori descritti consentono di eseguire interrogazioni specifiche che, nel complesso, contribuiscono al calcolo di un punteggio associato ad ogni risultato. Tale punteggio è utilizzato dal motore di ricerca di Open-DAM per ordinare i risultati ottenuti in base alla loro affinità con l’espressione di ricerca inserita.",
          "Di seguito sono presenti una serie di esempi che dimostrano come utilizzare le chiavi di ricerca con gli operatori precedentemente elencati:",
        ].join("<br />"),
        close_button: "Chiudi",
        column_char: "Carattere",
        column_description: "Descrizione",
        symbol: {
          plus: "+",
          minus: "-",
          empty: " ",
          distance: "@distance",
          gt_or_lt: "><",
          brackets: "()",
          tilde: "~",
          asterisk: "*",
          apex: '"',
        },
        description: {
          plus:
            "Un segno più iniziale o finale indica che questa parola deve essere presente in ogni risultato restituito.",
          minus: [
            "Un segno meno iniziale o finale indica che questa parola non deve essere presente in nessuna delle righe restituite. ",
            "<b>Nota bene:</b> l’operatore agisce solo per escludere le righe che altrimenti trovano corrispondenza con altri termini di ricerca. Pertanto, una ricerca con soli termini preceduti dal segno meno restituirà sempre un elenco di risultati vuoto.",
          ].join("<br />"),
          empty:
            "Per impostazione predefinita (quando non è specificato né + né -), la parola è facoltativa ma le righe che la contengono sono classificate più in alto.",
          distance: [
            "Verifica se due o più parole iniziano tutte entro una distanza specificata l’una dall’altra, misurata in parole.",
            "Esempio:",
            '<pre>"word1 word2 word3" @8</pre>',
          ].join("<br />"),
          gt_or_lt:
            "Questi due operatori sono utilizzati per modificare il contributo di una parola al valore di rilevanza assegnato a una riga.",
          brackets:
            "Le parentesi raggruppano le parole in sotto espressioni. I gruppi tra parentesi possono essere nidificati.",
          tilde:
            "Una tilde iniziale funge da operatore di negazione, facendo si che il contributo della parola alla rilevanza della riga sia negativo. Ciò è utile per contrassegnare le parole non significative. Una riga contenente una parola di questo tipo è valutata più bassa di altre, ma non viene esclusa del tutto come accade per l’operatore -.",
          asterisk: [
            "L’asterisco funge da operatore di troncamento (o carattere jolly).",
            "A differenza degli altri operatori, viene aggiunto alla parola da influenzare.",
            "Le parole corrispondono se iniziano con la parola che precedete l’operatore *.",
            "La parola jolly è considerata come un prefisso che deve essere presente all’inizio di una o più parole.",
            'Se la lunghezza minima della parola è 4, una ricerca di "+parola +*" potrebbe restituire meno righe di una ricerca di "+ parola +", poiché la seconda query ignora il termine di ricerca troppo breve.',
          ].join(" "),
          apex: [
            'Una frase racchiusa tra virgolette doppie (") corrisponde alle righe che contengono la frase letteralmente, così come è stata digitata.',
            "Il motore di ricerca suddividerà la frase in più parole per effettuare la ricerca.",
            "In questo caso non è necessario trovare una corrispondenza esatta: la ricerca per frase richiede solo che le corrispondenze contengano esattamente le stesse parole della frase e nello stesso ordine.",
            'Ad esempio, "frase di prova" corrisponde a "prova, frase".',
          ].join(" "),
        },
        examples: {
          example_1_expr: "fattura cliente",
          example_1_descr:
            "Ricerca tutte le righe che contengono almeno una delle due parole.",
          example_2_expr: "+fattura +cliente",
          example_2_descr:
            "Ricerca tutte le righe che contengono ambedue le parole.",
          example_3_expr: "+fattura cliente",
          example_3_descr:
            "Ricerca tutte le righe che contengono la parola fattura, ma da un punteggio maggiore se contengono anche Cliente.",
          example_4_expr: "+fattura -cliente",
          example_4_descr:
            "Ricerca tutte le righe che contengono la parola fattura ma non cliente.",
          example_5_expr: "+fattura ~cliente",
          example_5_descr:
            "Ricerca tutte le righe che contengono la parola fattura, ma se le righe contengono anche la parola cliente hanno un punteggio minore. Questa è una versione “soft” della ricerca precedentemente descritta all’interno della quale, se è contenuta la parola cliente, la riga non è proprio restituita.",
          example_6_expr: '+fattura +(>"cliente" <2020)',
          example_6_descr:
            "Ricerca tutte le righe che contengono la parola fattura e cliente, o fattura e 2020 (in qualsiasi ordine), ma attribuisce un punteggio maggiore alla prima combinazione (fattura e cliente).",
          example_7_expr: "fattur*",
          example_7_descr:
            "Ricerca tutte le righe che contengono la parola fattura, fatture, fatturazione o fatturato.",
          example_8_expr: '"fattura cliente"',
          example_8_descr:
            "Ricerca tutte le righe che contengono esattamente la frase fattura cliente (es. le righe che contengono fattura cliente ma non quelle che contengono fattura retrodatata cliente).",
        },
      },
    },
    archives: {
      name: "Archivio |||| Archivi",
      fields: {
        name: "Nome",
        size: "Dimensione",
        container: {
          tree_name: "Cartella di destinazione",
        },
        status: "Stato",
        imported_containers: "Cartelle Importate",
        imported_assets: "File Importati",
        modified: "Ultima Modifica",
        q: "Chiave di ricerca",
      },
      actions: {
        view_logs: "Visualizza Logs",
      },
    },
    "archive-logs": {
      name: "Log Import |||| Logs Import",
      fields: {
        q: "Chiave di ricerca",
        archive_id: "Archivio",
        type: "Tipo",
        created: "Data e Ora",
        message: "Messaggio",
      },
    },
    groups: {
      name: "Gruppo |||| Gruppi",
      fields: {
        code: "Codice",
        name: "Nome",
        modified: "Ultima Modifica",
      },
    },
    roles: {
      name: "Ruolo |||| Ruoli",
      fields: {
        code: "Codice",
        name: "Nome",
        created: "Data di creazione",
        modified: "Ultima Modifica",
      },
    },
    containers: {
      name: "Cartella",
    },
    perms: {
      fields: {
        propagate: "Propaga a tutte le sottocartelle",
        user: "Utente",
        group: "Gruppo",
        container: "Cartella",
        can_create: "Crea Sottocartelle",
        can_create_assets: "Crea File",
        can_read: "Leggi",
        can_read_logs: "Visualizza Logs",
        can_download: "Download",
        can_edit: "Modifica",
        can_edit_assets: "Modifica File",
        can_edit_perms: "Modifica Permessi",
        can_delete: "Cancella",
        can_delete_assets: "Cancella File",
      },
    },
    users: {
      name: "Utente |||| Utenti",
      fields: {
        is_active: "Attivo",

        roles: "Ruoli",
        groups: "Gruppi",
        profile: {
          name: "Nome",
          surname: "Cognome",
        },

        // Filters
        role: "Ruolo",
        group: "Gruppo",
        active: "Attivo",
        perms: "Permessi",
        q: "Chiave di ricerca",
      },
      type: {
        internal: "Interno",
        external: "Esterno",
      },
    },
    forms: {
      name: "Form |||| Forms",
      fields: {
        code: "Codice",
        name: "Nome",
        group: "Gruppo",
        modified: "Ultima Modifica",
        is_required: "Richiesto",
        use_for_containers: "Usa per le cartelle",
        use_for_assets: "Usa per i file",
        props: {
          name: "Campi",
          config: "Configurazione",
          fields: {
            name: "Nome",
            value: "Valore",
            type: "Tipo",
            is_required: "Obbligatorio",
          },
          types: {
            [TYPE_TEXT]: "Testo",
            [TYPE_TEXTAREA]: "Testo lungo",
            [TYPE_NUMBER]: "Numerico",
            [TYPE_DATE]: "Data",
            [TYPE_DATETIME]: "Data e Ora",
            [TYPE_SELECT]: "Selezione",
            [TYPE_SELECT_MULTI]: "Selezione Multipla",
            [TYPE_CHECKBOX]: "Checkbox",
          },
        },
      },
    },
    queries: {
      name: "Report |||| Reports",
      tabs: {
        general: "Generali",
        query: "Query",
        columns: "Colonne",
        filters: "Filtri",
      },
      fields: {
        query_text: "SQL Statement",
        query_text_run: "Esegui",
        query_text_map_columns: "Mappa colonne",
        per_page: "Numero di record per pagina",
        resource: "Nome della risorsa mappata",
        name: "Nome della query",
        show_aside: "Mostra dettaglio laterale",
        groups: "Gruppi",
        columns: {
          name: "Colonne",
          fields: {
            name: "Colonna",
            type: "Tipo",
            alias: "Alias",
            aggregator: "Aggregatore",
            formatter: "Formato",
            is_hidden: "Nascondi",
          },
        },
        filters: {
          name: "Filtri",
          fields: {
            sort: "Ordine di visualizzazione",
            alias: "Alias",
            column: "Colonna",
            qs_param: "Parametro Query (HTTP)",
            expression: "Espressione",
            is_alwayson: "Sempre visibile",
          },
          expression: "Expr",
          expressions: {
            eq: "=",
            not_eq: "<>",
            empty: "Vuoto",
            not_empty: "Non vuoto",
            lower: "<",
            lower_or_eq: "<=",
            greater: ">",
            greater_or_eq: ">=",
            in: "In",
            not_in: "Non In",
            like: "Contiene",
            ends_with: "Termina con",
            starts_with: "Inizia con",
            chosen_by_user: "Scelto dall'utente",
          },
        },
      },
    },
    maps: {
      name: "Mappa |||| Mappe",
      fields: {
        name: "Nome",
        created: "Data di creazione",
        modified: "Ultima modifica",
        shapes: {
          name: "Poligono",
        },
        q: "Chiave di ricerca",
      },
      actions: {
        new_shape: "Nuovo",
      },
      messages: {
        no_matches: "Nessuna cartella o file associati a questa area.",
      },
      map: {
        filter: {
          status_help:
            "Selezionando uno stato, verranno filtrate le aree sulla mappa in base a quello selezionato",
        },
        draw: {
          toolbar: {
            cancel: "Annulla",
            finish: "Termina",
            undo: "Annulla ultimo punto",
            polygon: "Disegna un Poligono",
            rectangle: "Disegna un Rettangolo",
          },
          handlers: {
            polygon_tooltip_start:
              "Clicca per iniziare a disegnare il poligono",
            polygon_tooltip_end: "Clicca per terminare il disegno del poligono",
            polygon_tooltip_cont:
              "Clicca per continuare a disegnare il poligono",
            polygon_tooltip_finish:
              "Clicca sull'ultimo punto per terminare il poligono",
            rectangle: "Disegna un Rettangolo",
            rectangle_tooltip_start:
              "Clicca per iniziare a disegnare il rettangolo",
            simpleshape_tooltip_end:
              "Rilascia il mouse per terminare il disegno",
          },
        },
        edit: {
          toolbar: {
            save_title: "Salva modifiche",
            save_text: "Salva",
            cancel_title: "Annulla modifiche",
            cancel_text: "Annulla",
            clear_title: "Rimuovi tutti gli elementi",
            clear_text: "Rimuovi tutto",
            edit: "Modifica elementi",
            edit_disabled: "Nessun elemento modificabile",
            remove: "Rimuovi elementi",
            remove_disabled: "Nessun elemento eliminabile",
          },
          handlers: {
            edit_tooltip_text: "Clicca per annullare le modifiche",
            edit_tooltip_subtext:
              "Trascina gli angoli o il centro per effettuare le modifiche",
            remove_tooltip_text: "Clicca su un elemento per rimuoverlo",
          },
        },
        search: {
          placeholder: "Cerca un luogo",
        },
        polygon: {
          cilck_to_insert_name: "Clicca per inserire il nome dell'area",
        },
        color: "Colore",
        status: "Stato",
        status_active: "Attivo",
        status_all: "Tutti",
        status_disabled: "Disabilitato",
        status_help:
          'Quando lo stato dell\'area è "Disabilitato", non sarà possibile associarla ad un documento',
      },
    },
    "login-images": {
      fields: {
        user_id: "Username",
        name: "Immagine",

        // Create
        file: "Carica un'immagine",
      },
    },
    "user-profile": {
      fields: {
        name: "Nome",
        surname: "Cognome",
      },
    },
    "user-password-change": {
      fields: {
        old_password: "Vecchia password",
        new_password: "Nuova password",
        confirm_password: "Conferma password",
      },
    },
  },
};

messages.resources.recycler = messages.resources.explorer;

export default messages;
