import React, { Fragment } from "react";
import { Title, translate } from "react-admin";
import { Typography, createStyles, withStyles } from "@material-ui/core";

import HelpIcon from "@material-ui/icons/LiveHelp";
import classnames from "classnames";
import { compose } from "recompose";

const styles = (theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        padding: "1em",
      },
      opacity: 0.5,
    },
    title: {
      display: "flex",
      alignItems: "center",
    },
    icon: {
      width: "2em",
      height: "2em",
      marginRight: "0.5em",
    },
    panel: {
      marginTop: "1em",
    },
    panelDetails: {
      whiteSpace: "pre-wrap",
    },
    toolbar: {
      marginTop: "2em",
    },
    message: {
      width: "50%",
      textAlign: "justify",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  });

const Welcome = ({ classes, permissions, translate }) => (
  <Fragment>
    <Title title={"dashboard.welcome.name"} />
    <div className={classnames(classes.container)}>
      <h1 className={classes.title} role="alert">
        <HelpIcon className={classes.icon} />
        {translate("dashboard.welcome.title")}
      </h1>
      <Typography component="p" variant="body1" className={classes.message}>
        {translate("dashboard.welcome.message_1")}
      </Typography>
      <br />
      <Typography component="p" variant="body1" className={classes.message}>
        {translate("dashboard.welcome.message_2")}
      </Typography>
      {permissions && <br />}
      {permissions && (
        <Typography variant="display1">
          {localStorage.getItem("username")}
        </Typography>
      )}
    </div>
  </Fragment>
);
export default compose(withStyles(styles), translate)(Welcome);
