import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  translate,
  Toolbar,
  SaveButton
} from "react-admin";

const ProfileToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const ProfileEdit = ({ translate, ...props }) => {
  return (
    <Edit {...props} title={translate("app.my_profile")} undoable={false}>
      <SimpleForm redirect={false} toolbar={<ProfileToolbar />}>
        <TextInput
          source="profile.name"
          label={translate("resources.user-profile.fields.name")}
        />
        <TextInput
          source="profile.surname"
          label={translate("resources.user-profile.fields.surname")}
        />
        <TextInput source="email" type="email" />
      </SimpleForm>
    </Edit>
  );
};

export default translate(ProfileEdit);
