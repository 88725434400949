import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Map, TileLayer, withLeaflet } from "react-leaflet";
import { ReactLeafletSearch } from "react-leaflet-search";
import { translate } from "react-admin";
import compose from "recompose/compose";
import { createStyles, withStyles } from "@material-ui/core/styles";
import "leaflet/dist/leaflet.css";
import { GET_LIST, withDataProvider } from "ra-core";
import CircularProgress from "@material-ui/core/CircularProgress";

import { push } from "react-router-redux";
import { Paper, Drawer } from "@material-ui/core";

import MapContent from "./MapContent";
import MapShapeView from "./MapShapeView";

const styles = theme =>
  createStyles({
    root: {
      display: "flex"
    },
    list: {
      flexGrow: 1,
      transition: theme.transitions.create(["all"], {
        duration: theme.transitions.duration.enteringScreen
      }),
      marginRight: 0
    },
    map: {},
    loader: { position: "absolute", left: "50%", top: "50%", zIndex: 1000 },
    drawerPaper: {
      margin: 0,
      overflow: "hidden"
    }
  });

class MapViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      center: [40, 16],
      id: null,
      zoom: 5,
      maps: [],
      shape: null
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(document.URL.split("?")[1]);
    const id = parseInt(params.get("id"), 10) || null;
    if (params.has("zoom")) {
      const zoom = parseInt(params.get("zoom"), 10) || this.state.zoom;
      const lat = params.get("lat");
      const lng = params.get("lng");

      this.setState({ zoom, center: [lat, lng], id });
    }

    const { dataProvider } = this.props;
    dataProvider(GET_LIST, "maps", {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "name", order: "asc" }
    }).then(response => {
      if (id) {
        let shapes = response.data.reduce(
          (shapes, map) => shapes.concat(map.shapes),
          []
        );
        let shape = shapes.find(s => s.id === id);
        this.setState({ maps: response.data, shape });
      } else {
        this.setState({ maps: response.data });
      }
    });
  }

  onMoveEnd(event) {
    const { dispatch, push } = this.props;
    const zoom = event.target.getZoom();
    const center = event.target.getCenter();
    const params = new URLSearchParams(document.URL.split("?")[1]);
    if (params.has("zoom")) {
      params.set("zoom", zoom);
      params.set("lat", center.lat);
      params.set("lng", center.lng);
    } else {
      params.append("zoom", zoom);
      params.append("lat", center.lat);
      params.append("lng", center.lng);
    }

    const route = {
      pathname: "map-viewer",
      search: params.toString()
    };
    dispatch(push(route));
    this.setState({ zoom, center: [center.lat, center.lng] });
  }

  onMapChange(center, zoom) {
    this.setState({ center, zoom });
  }

  onShapeClick(shape) {
    const { dispatch, push } = this.props;
    const params = new URLSearchParams(document.URL.split("?")[1]);
    params.set("id", shape.id);
    const route = {
      pathname: "map-viewer",
      search: params.toString()
    };
    this.setState({ shape }, () => dispatch(push(route)));
  }
  onShapeCancel() {
    const { dispatch, push } = this.props;
    this.setState({ shape: null });
    const params = new URLSearchParams(document.URL.split("?")[1]);
    params.set("id", null);
    const route = {
      pathname: "map-viewer",
      search: params.toString()
    };
    this.setState({ shape: null }, () => dispatch(push(route)));
  }

  render() {
    const { translate, classes } = this.props;
    const { maps } = this.state;
    const Search = withLeaflet(ReactLeafletSearch);
    return (
      <Paper>
        <Map
          ref="map"
          maxZoom={19}
          center={this.state.center}
          zoom={this.state.zoom}
          className={classes.map}
          style={{ height: window.innerHeight - 120 }}
          onMoveEnd={this.onMoveEnd.bind(this)}
        >
          <TileLayer
            maxZoom={21}
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          <Search
            position="topleft"
            provider="OpenStreetMap"
            inputPlaceholder={translate(
              "resources.maps.map.search.placeholder"
            )}
            zoom={16}
            showPopup={false}
            showMarker={false}
          />
          {(!maps || maps.length === 0) && (
            <CircularProgress className={classes.loader} />
          )}
          <MapContent
            id={this.state.id}
            maps={maps}
            zoom={this.state.zoom}
            onMarkerClick={this.onMapChange.bind(this)}
            onShapeClick={this.onShapeClick.bind(this)}
            onChangeMap={this.onMapChange.bind(this)}
          />
        </Map>

        <Drawer
          variant="persistent"
          classes={{
            paper: classes.drawerPaper
          }}
          open={this.state.shape !== null}
          anchor="right"
        >
          {this.state.shape && (
            <MapShapeView
              record={this.state.shape}
              onCancel={() => this.onShapeCancel()}
            />
          )}
        </Drawer>
      </Paper>
    );
  }
  renderMap(data) {}
}

MapViewer.propTypes = {
  dataProvider: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired
};

export default compose(
  connect(_ => ({ push })),
  withDataProvider,
  translate,
  withStyles(styles)
)(MapViewer);
