import { LoginImageIcon } from "../icons";
import List from "./List";
import Create from "./Create";

export default {
  options: {
    group: "admin",
    roles: ["admin"]
  },
  icon: LoginImageIcon,
  list: List,
  create: Create
};
