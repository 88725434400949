import { CheckboxGroupInput, ReferenceArrayInput } from "react-admin";

import React from "react";
import { grey } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core";

const formatRoles = (roles) => (roles ? roles.map((r) => r.id) : []);
const parseRoles = (roles) => (roles ? roles.map((id) => ({ id })) : []);
const RolesInput = withStyles((theme) => ({
  root: {
    width: "100%",
    "& legend": {
      fontSize: 20,
      marginBottom: theme.spacing.unit * 0.5,
    },
    "& div": {
      borderTopColor: grey[300],
      borderTopStyle: "solid",
      borderTopWidth: 1,
      paddingTop: theme.spacing.unit,
    },
    "& label[for]": {
      width: 250,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& label[for] > span:first-child": {
      display: "flex",
      alignSelf: "flex-start",
    },
    "& label[for] > span:nth-child(2)": {
      marginTop: theme.spacing.unit * 0.5,
      alignSelf: "normal",
    },
  },
}))(({ classes, ...props }) => (
  <ReferenceArrayInput
    {...props}
    format={formatRoles}
    parse={parseRoles}
    className={classes.root}
    reference="roles"
  >
    <CheckboxGroupInput />
  </ReferenceArrayInput>
));
export default RolesInput;
