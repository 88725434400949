import { CREATE, DELETE, DELETE_MANY, GET_ONE, UPDATE } from "ra-core";

import { API_URL } from "./config";
import { fetchUtils } from "react-admin";
import getHeaders from "./authHeaders";

export const GET_EXPLORER_LOCATION = "GET_EXPLORER_LOCATION";
export const GET_ROOTS = "GET_ROOTS";
export const GET_CHILDREN = "GET_CHILDREN";
export const GET_ARCHIVES = "GET_ARCHIVES";
export const GET_MAP_SHAPE_ITEMS = "GET_MAP_SHAPE_ITEMS";
export const GET_LOGS = "GET_LOGS";
export const MOVE_CONTAINER = "MOVE_CONTAINER";
export const MOVE_ASSET = "MOVE_ASSET";
export const CHECKOUT = "CHECKOUT";
export const CHECKIN = "CHECKIN";
export const CLONE = "CLONE";

const addExplorerFeature = (requestHandler) => (type, resource, params) => {
  const { fetchJson } = fetchUtils;
  if (resource === "explorer" && type === CLONE) {
    const url = `${API_URL}/explorer/clone/${params.record_id}`;
    return fetch(url, {
      method: "POST",
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then(({ data }) => ({ data }));
  } else if (
    resource === "explorer" &&
    [CHECKIN, CHECKOUT].indexOf(type) !== -1
  ) {
    const url = `${API_URL}/${params.resource}/${type.toLowerCase()}/${
      params.id
    }`;
    return fetch(url, {
      method: "POST",
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then(({ data }) => ({ data }));
  } else if (resource === "explorer" && type === GET_LOGS) {
    const url = `${API_URL}/explorer/${params.id}/${params.type}/logs`;
    return fetch(url, {
      method: "GET",
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then(({ data }) => ({ data }));
  } else if (resource === "explorer" && type === MOVE_CONTAINER) {
    const url = `${API_URL}/explorer/containers/move/${params.sourceContainerId}/to/${params.destinationContainerId}`;
    return fetch(url, {
      method: "PATCH",
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then(({ data }) => ({ data }));
  } else if (resource === "explorer" && type === MOVE_ASSET) {
    const url = `${API_URL}/explorer/assets/move/${params.assetId}/to/${params.destinationContainerId}`;
    return fetch(url, {
      method: "PATCH",
      headers: getHeaders(),
    })
      .then((response) => response.json())
      .then(({ data }) => ({ data }));
  } else if (resource === "explorer" && type === GET_MAP_SHAPE_ITEMS) {
    const sort = params.sort || "name";
    const direction = params.direction || "asc";
    const url = `${API_URL}/explorer/map/${params.id}?sort=${sort}&direction=${direction}`;
    return fetchJson(url, {
      method: "GET",
      headers: getHeaders(),
    }).then((response) => ({
      data: response.json.matches.data,
    }));
  } else if (resource === "explorer" && type === GET_EXPLORER_LOCATION) {
    const url = `${API_URL}/explorer/${params.id}/location`;
    return fetchJson(url, {
      method: "GET",
      headers: getHeaders(),
    }).then((response) => ({
      data: response.json.data,
    }));
  } else if (resource === "explorer" && type === GET_ROOTS) {
    const url = `${API_URL}/explorer/roots`;
    return fetchJson(url, {
      method: "GET",
      headers: getHeaders(),
    }).then((response) => ({
      data: {
        roots: response.json.roots.data,
      },
    }));
  } else if (resource === "explorer" && type === GET_CHILDREN) {
    const url = `${API_URL}/explorer/${params.record_id}/children`;
    return fetchJson(url, {
      method: "GET",
      headers: getHeaders(),
    }).then((response) => ({
      data: {
        children: response.json.children.data,
      },
    }));
  } else if (resource === "explorer" && type === GET_ARCHIVES) {
    const url = `${API_URL}/explorer/${params.id}/archives`;
    return fetchJson(url, {
      method: "GET",
      headers: getHeaders(),
    }).then((response) => ({
      data: response.json.data,
    }));
  } else if (resource === "explorer" && type === DELETE_MANY) {
    params.ids = params.ids.map((id) => id.split("_").join("/"));
  } else if (
    resource === "explorer" &&
    [UPDATE, CREATE, DELETE, GET_ONE].indexOf(type) !== -1
  ) {
    let location = document.location.toString().split("/");
    resource = location[location.length - 1];
  }
  return requestHandler(type, resource, params);
};

export default addExplorerFeature;
