import React from "react";
import { Resource } from "ra-core";
import { QueryIcon } from "../icons";
import List from "./resource/List";

const createResource = query => {
  const options = {
    group: "reporting",
    title: query.name,
    roles: ["user", "admin"]
  };
  return (
    <Resource
      name={`query/${query.resource}`}
      icon={QueryIcon}
      options={options}
      list={List(query)}
    />
  );
};

export default createResource;
