import React from "react";

import { Datagrid as RaDatagrid, TextField } from "react-admin";
import formatters from "../columns/formatters";
import createAggregations from "./createAggregations";

const createDesktopColumn = column => {
  const Component = formatters[column.formatter] || TextField;
  return (
    <Component key={column.id} source={column.name} label={column.alias} />
  );
};
const Datagrid = ({ query, ...props }) => (
  <RaDatagrid {...props} {...createAggregations({ query, ...props })}>
    {query.columns
      .filter(c => !c.is_expandable && !c.is_hidden)
      .map(createDesktopColumn)}
  </RaDatagrid>
);
export default Datagrid;
