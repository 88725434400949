export default [
  {
    id: "info",
    name: "Info"
  },
  {
    id: "debug",
    name: "Debug"
  },
  {
    id: "warning",
    name: "Warning"
  },
  {
    id: "error",
    name: "Error"
  }
];
