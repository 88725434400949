import React from "react";
import PropTypes from "prop-types";
import {
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput,
  BooleanInput,
} from "react-admin";

const style = {
  display: "inline",
  float: "left",
  width: "auto",
  minWidth: 230,
};

const PermsInput = ({ disableRemove, formData, objectType, ...props }) => (
  <ArrayInput {...props} style={{ width: "100%" }}>
    <SimpleFormIterator disableRemove={disableRemove}>
      <ReferenceInput
        label="resources.perms.fields.group"
        source="group_id"
        reference="groups"
        defaultValue={0}
        filter={{
          _ids:
            formData && formData.perms && formData
              ? formData.perms.map((p) => p.group_id).filter((id) => id > 0)
              : [],
        }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <BooleanInput
        label="resources.perms.fields.can_edit_perms"
        source="can_edit_perms"
        style={style}
        defaultValue={false}
      />
      {objectType === "containers" && (
        <BooleanInput
          label="resources.perms.fields.can_create"
          source="can_create"
          style={style}
          defaultValue={true}
        />
      )}
      {objectType === "containers" && (
        <BooleanInput
          label="resources.perms.fields.can_create_assets"
          source="can_create_assets"
          style={style}
          defaultValue={true}
        />
      )}

      <BooleanInput
        label="resources.perms.fields.can_read"
        source="can_read"
        style={style}
        defaultValue={true}
      />

      <BooleanInput
        label="resources.perms.fields.can_read_logs"
        source="can_read_logs"
        style={style}
        defaultValue={false}
      />

      <BooleanInput
        label="resources.perms.fields.can_download"
        source="can_download"
        style={style}
        defaultValue={true}
      />
      <BooleanInput
        label="resources.perms.fields.can_edit"
        source="can_edit"
        style={style}
        defaultValue={true}
      />
      <BooleanInput
        label="resources.perms.fields.can_delete"
        source="can_delete"
        style={style}
        defaultValue={true}
      />
      {objectType === "containers" && (
        <BooleanInput
          label="resources.perms.fields.propagate"
          source="propagate"
          style={style}
          defaultValue={true}
        />
      )}
    </SimpleFormIterator>
  </ArrayInput>
);

PermsInput.propTypes = {
  canRemove: PropTypes.bool,
};

export default PermsInput;
