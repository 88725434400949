import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  ChipField
} from "react-admin";
import Filters from "./Filters";
import BulkActions from "../components/list/BulkActions";

const GroupList = props => (
  <React.Fragment>
    <List
      {...props}
      perPage={10}
      filters={<Filters />}
      bulkActionButtons={<BulkActions />}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <ChipField source="code" />
        <TextField source="name" />
        <DateField source="modified" showTime />
        <EditButton />
      </Datagrid>
    </List>
  </React.Fragment>
);
export default GroupList;
