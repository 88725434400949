import { Button, withStyles } from "@material-ui/core";
import { Confirm, Mutation, crudUpdate, translate } from "react-admin";
import React, { useState } from "react";

import DoneAllIcon from "@material-ui/icons/DoneAll";
import OfflinePinIcon from "@material-ui/icons/OfflinePin";
import { compose } from "recompose";
import { connect } from "react-redux";
import { get } from "lodash";
import makeid from "../../utils/makeid";

const styles = (theme) => ({
  button: {
    marginLeft: 0,
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
  },
});

const saveAndCheckIn = (resource, values, previousData, basePath) =>
  crudUpdate(
    resource,
    values.id,
    { ...values, checkin: true },
    previousData,
    basePath,
    false,
    true
  );

const CheckInOutButtonComponent = ({ classes, translate, ...props }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { record, resource, basePath, handleSubmit, saveAndCheckIn } = props;
  const { can_checkout, can_checkin } = record;

  const handleCheckin = handleSubmit((values) =>
    saveAndCheckIn(resource, values, record, basePath)
  );

  const mode = can_checkout ? "checkout" : "checkin";
  if (props.resource !== "assets") {
    return null;
  }

  if (!can_checkout && !can_checkin) {
    return null;
  }

  const options = {
    undoable: false,
    onSuccess: {
      refresh: true,
      notification: {
        body: `resources.explorer.messages.${mode}_executed`,
        level: "info",
      },
    },
    onFailure: {
      notification: {
        body: `resources.explorer.messages.${mode}_failed`,
        level: "info",
      },
    },
  };
  return (
    <>
      <Button
        onClick={handleOpen}
        variant="contained"
        color="primary"
        icon={mode === "checkout" ? <DoneAllIcon /> : <OfflinePinIcon />}
        className={classes.button}
      >
        {mode === "checkout" ? <DoneAllIcon /> : <OfflinePinIcon />}
        {translate(`resources.explorer.actions.${mode}`)}
      </Button>
      <Mutation
        type={mode.toUpperCase()}
        resource="explorer"
        payload={{
          id: get(props, "record.id"),
          resource: get(props, "resource"),
        }}
        options={options}
      >
        {(handleCheckout) => (
          <Confirm
            key={makeid(8)}
            isOpen={open}
            title={`resources.explorer.titles.${mode}`}
            content={`resources.explorer.messages.${mode}`}
            onConfirm={() =>
              !setOpen(false) && can_checkout
                ? handleCheckout()
                : handleCheckin()
            }
            onClose={handleClose}
          />
        )}
      </Mutation>
    </>
  );
};
const CheckInOutButton = compose(
  translate,
  withStyles(styles),
  connect(undefined, { saveAndCheckIn })
)(CheckInOutButtonComponent);

export default CheckInOutButton;
