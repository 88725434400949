import React from "react";
import { withTranslate } from "react-admin";
import { withStyles, Typography } from "@material-ui/core";
import { get } from "lodash";
import { compose } from "recompose";
const styles = (theme) => ({
  warn: {
    marginTop: theme.spacing.unit * 2,
  },
});
const CheckInOutStatusField = ({ record, classes, type, translate }) =>
  type !== "assets" ? null : (
    <>
      {get(record, "is_checked_out") && !get(record, "can_checkin") && (
        <Typography variant="body2" color="error" className={classes.warn}>
          {translate(
            "resources.explorer.messages.checked_out_from",
            record.owner
          )}
        </Typography>
      )}
      {get(record, "can_checkin") && (
        <Typography variant="body2" className={classes.warn}>
          {translate("resources.explorer.messages.checked_out")}
        </Typography>
      )}
    </>
  );
export default compose(
  withTranslate,
  withStyles(styles)
)(CheckInOutStatusField);
