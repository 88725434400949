import { Button } from "react-admin";
import CreateIcon from "@material-ui/icons/Create";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";
import { get } from "lodash";
import { linkToRecord } from "ra-core";
import shouldUpdate from "recompose/shouldUpdate";

const stopPropagation = (e) => e.stopPropagation();
const linkWithListOptions = ({ link, currentSort, page, perPage }) =>
  currentSort
    ? `${link}/?sort=${currentSort.field}&order=${currentSort.order}&page=${page}&perPage=${perPage}`
    : `${link}/`;
const EditButton = ({
  basePath = "",
  record = {},
  source = "id",
  currentSort,
  page,
  perPage,
  ...rest
}) =>
  ["container", "asset"].indexOf(record.type) !== -1 &&
  get(record, "id") !== "prev" &&
  get(record, "perms.can_edit") && (
    <Button
      component={Link}
      to={linkWithListOptions({
        link: `${linkToRecord(basePath, record.record_id)}/${record.type}s`,
        currentSort,
        page,
        perPage,
      })}
      label={
        get(record, "perms.can_checkout") || get(record, "perms.can_checkin")
          ? "ra.action.edit"
          : "ra.action.show"
      }
      onClick={stopPropagation}
      {...rest}
    >
      {get(record, "perms.can_checkout") || get(record, "perms.can_checkin") ? (
        <CreateIcon />
      ) : (
        <ImportContactsIcon />
      )}
    </Button>
  );

EditButton.propTypes = {
  currentSort: PropTypes.any,
  basePath: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  record: PropTypes.object,
};

const enhance = shouldUpdate(
  (props, nextProps) =>
    props.translate !== nextProps.translate ||
    (props.record &&
      nextProps.record &&
      props.record.id !== nextProps.record.id) ||
    props.basePath !== nextProps.basePath ||
    (props.record == null && nextProps.record != null) ||
    (props.record.perms &&
      (nextProps.record.perms !== props.record.perms ||
        props.record.perms.can_edit !== nextProps.record.perms.can_edit)) ||
    props.currentSort !== nextProps.currentSort
);

export default enhance(EditButton);
