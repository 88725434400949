import React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";
import MapInput from "./input/MapInput";

const MapCreate = props => (
  <Edit {...props} undoable={false}>
    <SimpleForm redirect="list" undoable={true} submitOnEnter={false}>
      <TextInput source="name" options={{ fullWidth: true }} />
      <MapInput source="shapes" />
    </SimpleForm>
  </Edit>
);

export default MapCreate;
