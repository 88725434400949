import React from "react";
import { Query } from "ra-core";
import Breadcrumbs from "./Breadcrumbs";
import { GET_EXPLORER_LOCATION } from "../addExplorerFeature";

const Location = ({ id }) => (
  <React.Fragment>
    {id === null && (
      <Breadcrumbs loading={false} breadcrumbs={[]} showMode="location" />
    )}
    {id !== null && (
      <Query resource="explorer" type={GET_EXPLORER_LOCATION} payload={{ id }}>
        {({ data, loading }) => (
          <Breadcrumbs
            loading={loading}
            breadcrumbs={loading ? [] : data !== null ? data : []}
            showMode="location"
          />
        )}
      </Query>
    )}
  </React.Fragment>
);

export default Location;
