import React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";

const EditGroup = props => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <TextInput source="code" />
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

export default EditGroup;
