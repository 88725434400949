import React from "react";
import { SelectInput } from "react-admin";

const VIEW_KIND = ["checked_out"].map((id) => ({
  id,
  name: `resources.explorer.view_kind.${id}`,
}));

const ViewKindInput = (props) => <SelectInput {...props} choices={VIEW_KIND} />;
export default ViewKindInput;
