import { amber } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
import indigo from "@material-ui/core/colors/indigo";
import red from "@material-ui/core/colors/red";

export default createMuiTheme({
  typography: {
    fontSize: 15,
  },
  palette: {
    background: {
      default: "#EDECEC",
    },
    primary: indigo,
    secondary: amber,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.3,
  },
  overrides: {},
});
