import { ReferenceInput, SelectInput } from "react-admin";

import React from "react";

const GroupsSelectInput = (props) => (
  <ReferenceInput
    {...props}
    reference="groups"
    sort={{ field: "name", order: "ASC" }}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>
);
export default GroupsSelectInput;
