import React from "react";
import { List as RaList, Responsive } from "react-admin";
import Filters from "./Filters";
import Datagrid from "./Datagrid";
import expressions from "../columns/expressions";

const List = query => props => (
  <RaList
    {...props}
    title={query.name}
    perPage={query.per_page}
    filters={<Filters query={query} />}
    bulkActionButtons={false}
    filterDefaultValues={query.filters
      .filter(filter => filter.expression !== expressions.CHOSEN_BY_USER)
      .reduce(
        (defaultFilters, filter) => ({
          ...defaultFilters,
          [`${filter.qs_param}_expr`]: filter.expression
        }),
        {}
      )}
    sort={{ field: query.sort, direction: query.direction }}
  >
    <Responsive medium={<Datagrid query={query} />} />
  </RaList>
);
export default List;
