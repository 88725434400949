import React from "react";
import { SelectInput } from "react-admin";

const TYPES = ["internal", "external"].map((id) => ({
  id,
  name: `resources.users.type.${id}`,
}));

const TypeInput = (props) => <SelectInput {...props} choices={TYPES} />;
export default TypeInput;
