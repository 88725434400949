import Icon from "@material-ui/icons/DeleteSweep";
import List from "./List";

export default {
  options: {
    group: "dashboard",
    roles: ["admin"],
  },
  icon: Icon,
  list: List,
};
