import React from "react";
import { Query, translate } from "react-admin";
import { GET_ARCHIVES } from "../addExplorerFeature";
import { Typography } from "@material-ui/core";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
const styles = theme => ({
  paragraph: {
    display: "flex",
    alignItems: "center",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing.unit * 3
  }
});

const ArchiveWarn = ({ id, classes, translate }) =>
  id > 0 ? (
    <Query type={GET_ARCHIVES} resource="explorer" payload={{ id }}>
      {({ data, loading, error }) => {
        if (loading) {
          return null;
        }
        if (error) {
          return null;
        }
        if (!data.archives || data.archives.length === 0) {
          return null;
        }
        return (
          <Typography className={classes.paragraph} variant="body2" paragraph>
            {translate("resources.explorer.messages.importing_archives", {
              count: data.archives.length
            })}
          </Typography>
        );
      }}
    </Query>
  ) : null;
export default compose(translate, withStyles(styles))(ArchiveWarn);
