import { QueryIcon } from "../icons";
import List from "./List";
import Create from "./Create";
import Edit from "./Edit";

export default {
  icon: QueryIcon,
  options: {
    group: "admin",
    roles: ["admin"]
  },
  list: List,
  edit: Edit,
  create: Create
};
