import React, { useState, Fragment } from "react";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-markup-templating.js";
import "prismjs/components/prism-clike.js";
import "prismjs/components/prism-php.js";
import "prismjs/components/prism-sql.js";
import "prismjs/themes/prism.css";

import compose from "recompose/compose";
import { change } from "redux-form";
import { REDUX_FORM_NAME, Labeled, addField, translate } from "react-admin";
import { connect } from "react-redux";
import {
  Divider,
  FormHelperText,
  withStyles,
  createStyles,
  Button,
  Tooltip,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";

import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import { withDataProvider, showNotification } from "ra-core";
import { EXEC_QUERY, MAP_COLUMNS } from "../../addQueriesFeature";

const styles = theme =>
  createStyles({
    errorDivider: {
      backgroundColor: "red",
      height: 2
    },
    divider: {
      height: 1,
      opacity: 0.42,
      backgroundColor: "black"
    },
    toolbar: {
      paddingTop: theme.spacing.unit * 1
    },
    toolbarButton: {
      marginTop: theme.spacing.unit * 1,
      marginRight: theme.spacing.unit * 1
    },
    console: {
      clear: "both",
      paddingTop: theme.spacing.unit * 1
    },
    consoleTimestamp: {
      marginRight: theme.spacing.unit * 1
    },
    errorLine: {
      color: "red",
      fontFamily: "Fira Code, Consolas, Courier New"
    },
    infoLine: {
      fontFamily: "Fira Code, Consolas, Courier New"
    }
  });
const handleToolbarItemClick = (
  dataProvider,
  { resource, action, callback, text = undefined, record = undefined }
) =>
  dataProvider(action, resource, { text, record }).then(response =>
    callback(response)
  );

const ButtonGroup = ({ children, ...props }) => <div>{children}</div>;

const CodeEditorToolbar = ({
  classes,
  translate,
  resource,
  dataProvider,
  text,
  record,
  onExecute,
  onMapColumns
}) => (
  <ButtonGroup className={classes.toolbar}>
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={translate(`resources.queries.fields.query_text_run`)}
    >
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.toolbarButton}
        onClick={handleToolbarItemClick.bind(this, dataProvider, {
          resource,
          action: EXEC_QUERY,
          callback: onExecute,
          text,
          record
        })}
      >
        <PlayArrowIcon />
      </Button>
    </Tooltip>
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={translate(`resources.queries.fields.query_text_map_columns`)}
    >
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.toolbarButton}
        onClick={handleToolbarItemClick.bind(this, dataProvider, {
          resource,
          action: MAP_COLUMNS,
          callback: onMapColumns,
          text,
          record
        })}
      >
        <PlaylistAddCheckIcon />
      </Button>
    </Tooltip>
  </ButtonGroup>
);
const CodeEditorInput = ({
  record,
  source,
  translate,
  resource,
  classes,
  columns,
  dataProvider,
  meta: { touched, error },
  dispatch,
  toolbar = true,
  ...props
}) => {
  const [text, setText] = useState(props.input.value || record[source] || "");
  const [queryResult, setQueryResult] = useState([]);
  const handleChange = text => {
    setText(text);
    dispatch(change(REDUX_FORM_NAME, source, text));
  };
  const handleColumnsMap = ({ data }) => {
    dispatch(
      showNotification(data.message, data.code !== 200 ? "warning" : "info")
    );
    if (data.code === 200) {
      dispatch(
        change(
          REDUX_FORM_NAME,
          "columns",
          data.columns.map((column, i) => ({
            alias: column.name,
            ...((columns || []).find(c => c.name === column.name) || {}),
            ...column,
            // Sort has biggest weight
            sort: i
          }))
        )
      );
    }
  };
  return (
    <Fragment>
      <Labeled
        meta={{ touched, error }}
        label={translate(`resources.${resource}.fields.${source}`)}
        className="code-editor"
      >
        <Editor
          value={text}
          className={"ide"}
          onValueChange={handleChange}
          highlight={text => highlight(text, languages.sql)}
          padding={0}
          style={{
            fontFamily: "Fira Code, Consolas, Courier New",
            fontSize: 15,
            padding: 0,
            margin: 0,
            minHeight: 55,
            border: "white",
            outline: "none",
            width: "100%"
          }}
        />
      </Labeled>

      <Divider
        className={
          (!!touched && error && classes.errorDivider) || classes.divider
        }
      />
      {!!touched && error && <FormHelperText error>{error}</FormHelperText>}
      {toolbar === true && (
        <CodeEditorToolbar
          dataProvider={dataProvider}
          resource={resource}
          translate={translate}
          classes={classes}
          text={text}
          record={record}
          onLoad={response => setText(response.data)}
          onExecute={({ data }) => {
            dispatch(
              showNotification(
                data.code !== 200
                  ? data.message
                  : data.results.length === 0
                  ? "app.no_records"
                  : data.message,
                data.code !== 200 ? "warning" : "info"
              )
            );
            if (data.code === 200) {
              setQueryResult(data.results);
            }
          }}
          onMapColumns={handleColumnsMap}
        />
      )}
      {queryResult !== null && (
        <Table padding="none">
          {queryResult.length > 0 && (
            <TableHead>
              <TableRow>
                {Object.keys(queryResult[0]).map(column => (
                  <TableCell key={column}>{column}</TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          {queryResult.length > 0 && (
            <TableBody>
              {queryResult.map((row, i) => (
                <TableRow key={i}>
                  {Object.keys(row).map(column => (
                    <TableCell key={column}>{row[column]}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      )}
    </Fragment>
  );
};
export default compose(
  addField,
  translate,
  withStyles(styles),
  withDataProvider,
  connect()
)(CodeEditorInput);
