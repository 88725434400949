import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { translate } from "react-admin";
import compose from "recompose/compose";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { Query } from "ra-core";
import GetAppIcon from "@material-ui/icons/GetApp";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "./List";
import { Button } from "@material-ui/core";
import { API_URL } from "../config";
import { GET_MAP_SHAPE_ITEMS } from "../addExplorerFeature";

const styles = theme =>
  createStyles({
    root: {
      padding: theme.spacing.unit * 3,
      paddingTop: 50
    },
    closeButton: {
      marginRight: -theme.spacing.unit * 2
    },
    colorButton: {
      margin: theme.spacing.unit * 1
    },
    title: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    form: {
      [theme.breakpoints.up("xs")]: {
        width: 400,
        overflow: "hidden"
      },
      [theme.breakpoints.down("xs")]: {
        width: "100vw",
        marginTop: -30
      }
    },
    field: {
      marginTop: theme.spacing.unit * 3,
      width: "100%"
    },
    buttonsContainer: {
      position: "fixed",
      bottom: 0,
      width: "100%",
      paddingLeft: theme.spacing.unit * 3,
      paddingBottom: 12,
      paddingTop: 12,
      backgroundColor: "#f5f5f5",
      marginLeft: -theme.spacing.unit * 3
    },
    button: {
      color: "white !important"
    }
  });

class MapShapeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.record.id,
      name: props.record.name
    };
  }

  componentDidUpdate(prevProps) {
    const prevRecord = prevProps.record;
    const currentRecord = this.props.record;
    if (prevRecord.code !== currentRecord.code) {
      this.setState({
        id: currentRecord.id,
        name: currentRecord.name
      });
    }
  }

  render() {
    const { translate, classes, onCancel } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <Typography variant="subheading">{this.state.name}</Typography>
          <IconButton onClick={onCancel} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.form}>
          <Query
            resource="explorer"
            type={GET_MAP_SHAPE_ITEMS}
            payload={{
              id: this.state.id
            }}
          >
            {({ loading, data, error }) =>
              loading ? (
                <CircularProgress color="primary" />
              ) : (
                <List data={data} />
              )
            }
          </Query>
        </div>
        <div className={classes.buttonsContainer}>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            href={`${API_URL}/explorer/${
              this.state.id
            }/shape/download?token=${localStorage.getItem("token")}`}
          >
            <GetAppIcon />
            {translate("resources.explorer.actions.download")}
          </Button>
        </div>
      </div>
    );
  }
}

export default compose(translate, withStyles(styles))(MapShapeView);
