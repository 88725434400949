import { BooleanInput, Filter, TextInput } from "react-admin";

import React from "react";

const Filters = (props) => (
  <Filter {...props}>
    <TextInput source="q" alwaysOn />
    <BooleanInput source="active" />
  </Filter>
);
export default Filters;
