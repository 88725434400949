import React from "react";
import { List, Datagrid, TextField, DateField, ChipField } from "react-admin";
import SizeField from "./fields/SizeField";
import Filters from "./Filters";
import ViewLogsButton from "./ViewLogsButton";

const ArchiveList = props => (
  <List {...props} perPage={25} filters={<Filters />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <SizeField source="size" />
      <TextField source="container.tree_name" />
      <ChipField source="status" color="primary" />
      <ChipField source="imported_containers" />
      <ChipField source="imported_assets" />
      <DateField source="modified" showTime />
      <ViewLogsButton />
    </Datagrid>
  </List>
);
export default ArchiveList;
