import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { change } from "redux-form";
import { REDUX_FORM_NAME } from "ra-core";
import {
  Map,
  TileLayer,
  FeatureGroup,
  Rectangle,
  Polygon,
  withLeaflet,
  Tooltip
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { ReactLeafletSearch } from "react-leaflet-search";
import { translate } from "react-admin";
import compose from "recompose/compose";
import L from "leaflet";
import makeid from "../../utils/makeid";
import Drawer from "@material-ui/core/Drawer";
import { createStyles, withStyles } from "@material-ui/core/styles";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import MapShapeInput from "./MapShapeInput";
import MapShapeStatus from "../filter/MapShapeStatus";

const styles = theme =>
  createStyles({
    root: {
      display: "flex"
    },
    list: {
      flexGrow: 1,
      transition: theme.transitions.create(["all"], {
        duration: theme.transitions.duration.enteringScreen
      }),
      marginRight: 0
    },
    listWithDrawer: {
      marginRight: 400
    },
    drawerPaper: {
      margin: 0,
      overflow: "hidden"
    }
  });

class MapInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      zoom: null,
      center: null,
      shapes: [],
      selected: null,
      handleLayerClick: true,
      status: "all"
    };
  }

  componentDidMount() {
    const { record, translate } = this.props;
    if (record.lat) {
      this.setState({ center: [record.lat, record.lng], zoom: record.zoom });
    } else {
      this.setState({ center: [40, 16], zoom: 5 });
    }

    if (record.shapes && record.shapes.length > 0) {
      this.setState({ shapes: record.shapes });
    }

    L.drawLocal.draw.toolbar = Object.assign(L.drawLocal.draw.toolbar, {
      actions: {
        text: translate("resources.maps.map.draw.toolbar.cancel")
      },
      finish: {
        text: translate("resources.maps.map.draw.toolbar.finish")
      },
      undo: {
        text: translate("resources.maps.map.draw.toolbar.undo")
      },
      buttons: {
        polygon: translate("resources.maps.map.draw.toolbar.polygon"),
        rectangle: translate("resources.maps.map.draw.toolbar.rectangle")
      }
    });

    L.drawLocal.draw.handlers = Object.assign(L.drawLocal.draw.handlers, {
      polygon: {
        tooltip: {
          start: translate(
            "resources.maps.map.draw.handlers.polygon_tooltip_start"
          ),
          cont: translate(
            "resources.maps.map.draw.handlers.polygon_tooltip_cont"
          ),
          end: translate("resources.maps.map.draw.handlers.polygon_tooltip_end")
        }
      },
      rectangle: {
        tooltip: {
          start: translate(
            "resources.maps.map.draw.handlers.rectangle_tooltip_start"
          )
        }
      },
      simpleshape: {
        tooltip: {
          end: translate(
            "resources.maps.map.draw.handlers.simpleshape_tooltip_end"
          )
        }
      }
    });

    L.drawLocal.edit.toolbar = Object.assign(L.drawLocal.edit.toolbar, {
      actions: {
        save: {
          title: translate("resources.maps.map.edit.toolbar.save_title"),
          text: translate("resources.maps.map.edit.toolbar.save_text")
        },
        cancel: {
          title: translate("resources.maps.map.edit.toolbar.cancel_title"),
          text: translate("resources.maps.map.edit.toolbar.cancel_text")
        },
        clearAll: {
          title: translate("resources.maps.map.edit.toolbar.clear_title"),
          text: translate("resources.maps.map.edit.toolbar.clear_text")
        }
      },
      buttons: {
        edit: translate("resources.maps.map.edit.toolbar.edit"),
        editDisabled: translate(
          "resources.maps.map.edit.toolbar.edit_disabled"
        ),
        remove: translate("resources.maps.map.edit.toolbar.remove"),
        removeDisabled: translate(
          "resources.maps.map.edit.toolbar.remove_disabled"
        )
      }
    });

    L.drawLocal.edit.handlers = Object.assign(L.drawLocal.edit.handlers, {
      edit: {
        tooltip: {
          subtext: translate(
            "resources.maps.map.edit.handlers.edit_tooltip_subtext"
          ),
          text: translate("resources.maps.map.edit.handlers.edit_tooltip_text")
        }
      },
      remove: {
        tooltip: {
          text: translate(
            "resources.maps.map.edit.handlers.remove_tooltip_text"
          )
        }
      }
    });
  }

  onEdit(event) {
    const { dispatch } = this.props;
    const editedLayers = event.layers.getLayers();
    let shapes = this.state.shapes;
    editedLayers.forEach(el => {
      /*
      const bounds = el.getBounds();
      const intersects = this.state.shapes.some(s =>
        bounds.intersects([s.coords])
      );
      if (intersects) {
        const oldShape = shapes.find(s => s.code === el.options.code);
        el.setLatLngs(oldShape.coords);
        return;
      }
      */

      const code = el.options.code;
      let shape = shapes.find(o => o.code === code);
      let coords = el.getLatLngs()[0].map(latLng => ({
        lat: latLng.lat,
        lng: latLng.lng
      }));
      shape.coords = coords;
    });
    dispatch(change(REDUX_FORM_NAME, "shapes", shapes));
    this.setState({ shapes });
  }

  onDeleteStart() {
    this.setState({ handleLayerClick: false });
  }

  onDeleteStop() {
    this.setState({ handleLayerClick: true });
  }

  onDelete(event) {
    const { dispatch } = this.props;
    const removedLayers = event.layers.getLayers();
    const codes = removedLayers.map(rl => rl.options.code);
    let shapes = this.state.shapes.filter(
      o => !codes.some(code => o.code === code)
    );
    dispatch(change(REDUX_FORM_NAME, "shapes", shapes));
    this.setState({ shapes });
  }

  onCreate(event) {
    const { dispatch } = this.props;
    const editControl = this.refs.edit.leafletElement;
    const featureGroup = editControl.options.edit.featureGroup;

    /*
    const bounds = event.layer.getBounds();
    const intersects = this.state.shapes.some(s =>
      bounds.intersects([s.coords])
    );
    if (intersects) {
      featureGroup.removeLayer(event.layer);
      return;
    }
    */
    let shape = { name: "", type: event.layerType, code: makeid(5) };
    let coords = event.layer.getLatLngs()[0].map(latLng => ({
      lat: latLng.lat,
      lng: latLng.lng
    }));
    shape.coords = coords;

    let shapes = this.state.shapes;
    shapes.push(shape);
    dispatch(change(REDUX_FORM_NAME, "shapes", shapes));
    this.setState({ shapes });

    featureGroup.removeLayer(event.layer);
  }

  onMoveEnd(event) {
    const { dispatch } = this.props;
    const zoom = event.target.getZoom();
    const center = event.target.getCenter();

    dispatch(change(REDUX_FORM_NAME, "lat", center.lat));
    dispatch(change(REDUX_FORM_NAME, "lng", center.lng));
    dispatch(change(REDUX_FORM_NAME, "zoom", zoom));

    this.setState({ zoom, center: [center.lat, center.lng] });
  }

  onLayerClick(layer) {
    if (!this.state.handleLayerClick) {
      return null;
    }

    const code = layer.target.options.code;
    const shape = this.state.shapes.find(s => s.code === code);
    this.setState({ selected: shape });
  }

  onLayerSubmit(shape) {
    const { dispatch } = this.props;
    const shapes = this.state.shapes;
    const s = shapes.find(s => s.code === shape.code);
    s.name = shape.name;
    s.color = shape.color;
    s.status = shape.status;
    this.setState({ shapes, selected: null });
    dispatch(change(REDUX_FORM_NAME, "shapes", this.state.shapes));
  }

  getVisibleShapes() {
    if (!this.state.shapes) {
      return [];
    }

    if (this.state.status !== "all") {
      return this.state.shapes.filter(s => s.status === this.state.status);
    }

    return this.state.shapes;
  }

  render() {
    const { translate, classes } = this.props;
    const Search = withLeaflet(ReactLeafletSearch);
    const shapes = this.getVisibleShapes();
    return (
      <Fragment>
        <Map
          maxZoom={19}
          onMoveEnd={this.onMoveEnd.bind(this)}
          center={this.state.center}
          zoom={this.state.zoom}
          style={{ height: 600 }}
        >
          <FeatureGroup ref="featureGroup">
            <EditControl
              ref="edit"
              position="topright"
              onEdited={this.onEdit.bind(this)}
              onCreated={this.onCreate.bind(this)}
              onDeleted={this.onDelete.bind(this)}
              onDeleteStart={this.onDeleteStart.bind(this)}
              onDeleteStop={this.onDeleteStop.bind(this)}
              draw={{
                circle: false,
                polyline: false,
                marker: false,
                circlemarker: false
              }}
            />

            {shapes.map((s, index) => {
              if (s.type === "rectangle") {
                return (
                  <Rectangle
                    key={index}
                    bounds={s.coords}
                    id={s.id}
                    code={s.code}
                    color={s.color}
                    onClick={this.onLayerClick.bind(this)}
                  >
                    <Tooltip>
                      {s.name ||
                        translate(
                          "resources.maps.map.polygon.cilck_to_insert_name"
                        )}
                    </Tooltip>
                  </Rectangle>
                );
              } else if (s.type === "polygon") {
                return (
                  <Polygon
                    key={index}
                    positions={s.coords}
                    id={s.id}
                    color={s.color}
                    code={s.code}
                    onClick={this.onLayerClick.bind(this)}
                  >
                    <Tooltip>
                      {s.name ||
                        translate(
                          "resources.maps.map.polygon.cilck_to_insert_name"
                        )}
                    </Tooltip>
                  </Polygon>
                );
              } else {
                return null;
              }
            })}
          </FeatureGroup>

          <TileLayer
            maxZoom={21}
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          <Search
            position="topleft"
            provider="OpenStreetMap"
            inputPlaceholder={translate(
              "resources.maps.map.search.placeholder"
            )}
            zoom={16}
            showPopup={false}
            showMarker={false}
          />

          <MapShapeStatus onChange={status => this.setState({ status })} />
        </Map>
        <Drawer
          variant="persistent"
          open={this.state.selected !== null}
          classes={{
            paper: classes.drawerPaper
          }}
          anchor="right"
        >
          {this.state.selected && (
            <MapShapeInput
              record={this.state.selected}
              onCancel={() => this.setState({ selected: null })}
              onSubmit={this.onLayerSubmit.bind(this)}
            />
          )}
        </Drawer>
      </Fragment>
    );
  }
}

MapInput.propTypes = {
  record: PropTypes.any.isRequired,
  source: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default compose(
  connect(_ => ({})),
  translate,
  withStyles(styles)
)(MapInput);
