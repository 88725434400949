import { Divider, Typography } from "@material-ui/core";

import React from "react";
import SearchHelpLink from "./SearchHelpLink";
import compose from "recompose/compose";
import { translate } from "react-admin";
import { withStyles } from "@material-ui/core/styles";

const parseRoute = (fn) => {
  let route = document.location.toString().split("#")[1];
  if (route.indexOf("?") !== -1) {
    let queryString = new URLSearchParams(route.split("?")[1]);
    let filter = JSON.parse(queryString.get("filter") || "{}");
    return fn({
      q: filter.q || null,
      id: filter.id || null,
      route: route.indexOf("/explorer") !== -1 ? "explorer" : "recycler",
    });
  }
  return fn({
    q: null,
    id: null,
    route: route.indexOf("/explorer") !== -1 ? "explorer" : "recycler",
  });
};

const EmptyMessageBox = withStyles((theme) => ({
  caption: {
    paddingTop: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    alignSelft: "center",
  },
}))(({ translate, total, classes, filterValues }) =>
  parseRoute(
    (filters) =>
      ((filters.route !== "recycler" && filters.q === null && total === 1) ||
        (filters.route !== "recycler" && filters.q !== null && total === 0) ||
        (filters.route === "recycler" && total === 0)) && (
        <Typography
          className={classes.caption}
          variant="subheading"
          component="div"
        >
          <p
            dangerouslySetInnerHTML={{
              __html: filterValues.q
                ? translate(
                    "resources.explorer.messages.no_data_for_search_expression",
                    { expression: filterValues.q }
                  )
                : translate("resources.explorer.messages.no_data"),
            }}
          />
          {filterValues.q && (
            <SearchHelpLink label="resources.explorer.search_help.read" />
          )}
          <Divider />
        </Typography>
      )
  )
);

export default compose(translate)(EmptyMessageBox);
