import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormControl, Typography, Divider } from "@material-ui/core";
import sanitizeRestProps from "../sanitizeRestProps";
import { withStyles } from "@material-ui/core/styles";
import compose from "recompose/compose";
import { REDUX_FORM_NAME, FormDataConsumer } from "ra-core";
import { change } from "redux-form";
import Field from "../../forms/input";

import LockIcon from "@material-ui/icons/Lock";
import TurnedInIcon from "@material-ui/icons/TurnedIn";

const styles = (theme) => ({
  group: {
    display: "block",
    clear: "both",
    marginTop: theme.spacing.unit * 2,
  },
  title: {
    paddingBottom: theme.spacing.unit * 1,
    fontWeight: "bold",
  },
  divider: {},
  input: {
    marginRight: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    width: "45%",
    minWidth: 250,
    display: "inline-flex",
  },
});
const emptyForm = {
  form: {
    id: 0,
    code: null,
    name: null,
  },
  props: [],
};

const getForm = ({ form, forms }) => {
  const theForm =
    form && form !== null
      ? form.form || forms[form.form_id] || emptyForm
      : emptyForm;
  return (fn) => fn(theForm);
};
const setFormProp = ({ form, prop, value, source, currentData, dispatch }) =>
  dispatch(
    change(
      REDUX_FORM_NAME,
      source,
      currentData
        .map((p) => ({
          prop: prop,
          form: p.form,
          name: p.name,
          value: p.form === form.code && p.name === prop.name ? value : p.value,
        }))
        .concat([
          !currentData.find((p) => p.form === form.code && p.name === prop.name)
            ? {
                prop: prop,
                form: form.code,
                name: prop.name,
                value,
              }
            : null,
        ])
        .filter((prop) => prop !== null)
    )
  );
const getFormProp = ({ form, prop, currentData }) => {
  const data = currentData.find(
    (p) =>
      p.form_prop_id === prop.id ||
      (p.form === form.code && p.name === prop.name)
  );
  return data ? data.value || "" : "";
};
const getFormPropError = ({ errors, form, prop, currentData }) => {
  const item = currentData.find(
    (cd) => cd.form === form.code && cd.name === prop.name
  );
  const propErrors =
    (item &&
      errors[currentData.indexOf(item)] &&
      errors[currentData.indexOf(item)].value) ||
    null;
  if (propErrors !== null) {
    if (typeof propErrors === "object") {
      let keys = Object.keys(propErrors);
      let message = keys.map((k) => propErrors[k]).join("\n");
      return message;
    } else {
      return propErrors;
    }
  }
  return null;
};

export const mapHashedFormsMap = (hashedMap) =>
  Object.keys(hashedMap).length > 0
    ? Object.keys(hashedMap).map((k) => hashedMap[k])
    : [];

export const isFormSelectable = (form, forms, selection) => {
  let allForms = mapHashedFormsMap(forms);
  let groupBy = form.group_by;
  let id = form.id;
  let count = selection
    .map((selected) => allForms.find((form) => form.id === selected.form_id))
    .filter((form) => form.group_by === groupBy && form.id !== id).length;

  return count === 0;
};

export const ensureFormRulesAreRespected = (forms, selection, type) => {
  // Recover all required forms.
  let allForms = mapHashedFormsMap(forms).filter(
    (f) =>
      (type === "containers" && f.use_for_containers) ||
      (type === "assets" && f.use_for_assets)
  );
  let requiredForms = allForms.filter(
    (f) => f.is_required && f.group_by === null
  );

  let lastSelectedForm =
    selection.length > 0 ? selection[selection.length - 1] : null;
  if (lastSelectedForm) {
    let associatedForm = allForms.find(
      (f) => f.id === lastSelectedForm.form_id
    );
    let isGroupByForm = associatedForm && associatedForm.group_by !== null;
    if (isGroupByForm) {
      let groupBy = associatedForm.group_by;
      let formsByGroup = allForms.filter(
        (f) => f.group_by === groupBy && f.id !== associatedForm.id
      );
      selection = selection.filter(
        (selected) => !formsByGroup.some((f) => f.id === selected.form_id)
      );
    }
  }
  let safeSelection = selection.concat(
    requiredForms
      .filter((r) => !selection.some((selected) => selected.form_id === r.id))
      .map((rf) => ({ form_id: rf.id }))
  );

  return safeSelection;
};

export const FormSelectItem = withStyles((theme) => ({
  item: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  label: {
    flex: "auto",
  },
  labelDisabled: {
    flex: "auto",
    color: "#d3d3d3",
    cursor: "not-allowed",
  },
  icon: {
    margin: 0,
    padding: 0,
    marginBottom: -1,
    marginLeft: theme.spacing.unit * 1,
    width: 20,
  },
}))(({ classes, choice, forms, formData }) => {
  const isSelectable = isFormSelectable(choice, forms, formData.forms);
  return (
    <span
      disabled={!isSelectable}
      className={classes.item}
      onClick={(e) =>
        ((choice.is_required && choice.group_by === null) || !isSelectable) &&
        e.stopPropagation()
      }
    >
      <span className={isSelectable ? classes.label : classes.labelDisabled}>
        {choice.name}
      </span>
      {choice.group_by !== null && <TurnedInIcon className={classes.icon} />}
      {choice.is_required && choice.group_by === null && (
        <LockIcon className={classes.icon} />
      )}
    </span>
  );
});

const Form = ({
  classes,
  resource,
  source,
  input,
  form,
  forms,
  errors,
  dispatch,
  record,
  ...props
}) =>
  getForm({ form, forms })((form) => (
    <FormDataConsumer>
      {({ formData }) => (
        <FormControl
          name={form.name}
          className={classes.group}
          {...sanitizeRestProps(props)}
        >
          <Typography className={classes.title} variant="subheading">
            {form.name}
          </Typography>
          <Divider className={classes.divider} />
          <br />
          {form.props.map((prop) => (
            <Field
              key={prop.id}
              error={getFormPropError({
                errors,
                form,
                prop,
                currentData: (formData && formData[source]) || [],
              })}
              prop={{
                ...prop,
                value: getFormProp({
                  form,
                  prop,
                  currentData: (formData && formData[source]) || [],
                }),
              }}
              className={classes.input}
              onChange={(value) =>
                setFormProp({
                  form,
                  prop,
                  value,
                  source,
                  dispatch,
                  currentData: formData[source],
                })
              }
            />
          ))}
        </FormControl>
      )}
    </FormDataConsumer>
  ));
Form.propTypes = {
  form: PropTypes.object.isRequired,
};
export const formatForms = (forms) =>
  forms && forms !== null && forms.map ? forms.map((r) => r.form_id) : [];
export const parseForms = (forms) =>
  forms && forms !== null && forms.map
    ? forms.map((id) => ({ form_id: id }))
    : [];

export const reloadForms = ({ value, forms, formData }) => {
  const ids = value.map((v) => v.form_id);
  const selectedForms = ids
    .map((id) => forms[id] || null)
    .filter((form) => form !== null);

  const selectedProps = selectedForms.reduce(
    (props, form) => props.concat(form.props.map((p) => ({ ...p, form }))),
    []
  );
  const filledProps = formData.props || [];
  return selectedProps
    .map((prop) => {
      let propData = filledProps.find(
        (fp) => fp.form === prop.form.code && fp.name === prop.name
      );
      if (propData) {
        return propData;
      }
      if (prop.is_required) {
        return {
          form: prop.form.code,
          name: prop.name,
          value: "",
        };
      }
      return null;
    })
    .filter((propData) => propData !== null);
};

export default compose(
  withStyles(styles),
  connect((state) => ({
    errors:
      (state.form &&
        state.form["record-form"] &&
        state.form["record-form"].submitErrors &&
        state.form["record-form"].submitErrors.props) ||
      {},
    forms:
      (state.admin &&
        state.admin.resources &&
        state.admin.resources.forms.data) ||
      {},
  }))
)(Form);
