import { API_URL } from "./config";
import getHeaders from "./authHeaders";

export const UPLOAD_ARCHIVE = "UPLOAD_ARCHIVE";

const addArchiveFeature = (requestHandler) => (type, resource, params) => {
  if (resource === "archives" && type === UPLOAD_ARCHIVE) {
    const url = `${API_URL}/explorer/import?container_id=${params.container_id}`;

    const headers = getHeaders();
    headers.delete("Content-Type");

    const data = new FormData();
    data.append("archive", params.archive);

    return fetch(url, {
      headers,
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((response) => ({ ...response }));
  }
  return requestHandler(type, resource, params);
};

export default addArchiveFeature;
