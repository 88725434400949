import React from "react";
import {
  ArrayInput,
  TextInput,
  SelectInput,
  BooleanInput,
  NumberInput
} from "react-admin";
import { FormDataConsumer, translate } from "ra-core";
import typeExpressionsMap from "../columns/typeExpressionsMap";
import SimpleFormIterator from "../../components/forms/SimpleFormIterator";

const style = {
  display: "inline",
  float: "left",
  marginRight: "20px",
  width: "auto"
};

const getValidExpressions = ({ translate, formData, scopedFormData }) => {
  if (!scopedFormData) {
    return [];
  }
  const { column } = scopedFormData;
  if (!column) {
    return [];
  }
  const mappedColumn = (formData.columns || []).find(c => c.name === column);
  if (!mappedColumn) {
    return [];
  }
  return (typeExpressionsMap[mappedColumn.type] || []).map(expr => ({
    id: expr,
    name: translate(`resources.queries.fields.filters.expressions.${expr}`)
  }));
};

const FiltersInput = ({ translate, ...props }) => (
  <FormDataConsumer>
    {({ formData }) => (
      <ArrayInput {...props} style={{ width: "100%" }} label="">
        <SimpleFormIterator>
          <NumberInput
            label="resources.queries.fields.filters.fields.sort"
            source="sort"
            style={style}
          />
          <SelectInput
            source="column"
            label="resources.queries.fields.filters.fields.column"
            choices={
              formData && formData !== null
                ? (formData.columns || []).map(column => ({
                    id: column.name,
                    name: column.alias || column.name
                  }))
                : []
            }
          />

          <TextInput
            label="resources.queries.fields.filters.fields.alias"
            source="alias"
            style={style}
          />
          <FormDataConsumer>
            {({ formData, scopedFormData, getSource, ...props }) => (
              <SelectInput
                {...props}
                fullWidth
                label="resources.queries.fields.filters.fields.expression"
                source={getSource("expression")}
                choices={
                  formData && formData !== null
                    ? getValidExpressions({
                        translate,
                        formData,
                        scopedFormData
                      })
                    : []
                }
                style={{ ...style, minWidth: 182, display: "block" }}
              />
            )}
          </FormDataConsumer>
          <TextInput
            label="resources.queries.fields.filters.fields.qs_param"
            source="qs_param"
            style={style}
          />
          <BooleanInput
            label="resources.queries.fields.filters.fields.is_alwayson"
            source="is_alwayson"
            style={style}
          />
        </SimpleFormIterator>
      </ArrayInput>
    )}
  </FormDataConsumer>
);
export default translate(FiltersInput);
