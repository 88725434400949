import makeid from "../utils/makeid";
export const ACTION = {
  MOVE_CONTAINER: "mc"
};
export const PARAMS = {
  ACTION: "a",
  SOURCE_ID: "sid",
  SOURCE_CONTAINER_ID: "scid",
  DESTINATION_ID: "did",
  DESTINATION_CONTAINER_ID: "dcid"
};

const refreshTree = (additionalParams = []) => {
  const currentLocation = document.location.toString();
  const params = currentLocation.split("#");
  const action = params[1].split("?");
  const qs = new URLSearchParams(action[1]);
  const filter = JSON.parse(qs.get("filter") || "{}");
  const queryString = Object.keys(additionalParams)
    .map(queryParam => `${queryParam}=${additionalParams[queryParam]}`)
    .join("&");
  document.location.href = `#${action[0]}?filter=${JSON.stringify({
    ...filter
  })}&reload=${makeid(5)}&${queryString}`;
};

/**
 * Comunica, attraverso la modifica dell'URL corrente, al FileSystemNavigator
 * che è opportuno aggiornare i dati poiché un asset è stato spostato.
 * @param {Object} asset
 * @param {Object} destination
 */
export const moveAsset = (asset, destination) => {
  refreshTree({
    action: "move-asset",
    source: asset.id,
    containerId: asset.container_id,
    destination: destination.id
  });
};

/**
 * Comunica, attraverso la modifica dell'URL corrente, al FileSystemNavigator
 * che è opportuno aggiornare i dati poiché un container è stato spostato.
 *
 * @param {Object} source
 * @param {Object} destination
 */
export const moveContainer = (source, destination) => {
  refreshTree({
    [PARAMS.ACTION]: `${ACTION.MOVE_CONTAINER}/${makeid(10)}`,
    [PARAMS.SOURCE_ID]: source.record_id,
    [PARAMS.SOURCE_CONTAINER_ID]: source.container_id,
    [PARAMS.DESTINATION_ID]: destination.record_id,
    [PARAMS.DESTINATION_CONTAINER_ID]: destination.container_id
  });
};

/**
 * Ripulisce l'URL corrente
 */
export const clearAction = () => refreshTree({});
export const isMoveContainer = action =>
  action.startsWith(ACTION.MOVE_CONTAINER);
