import React, { Component } from "react";
import compose from "recompose/compose";
import classNames from "classnames";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import MuiTextField from "@material-ui/core/TextField";
import { withStyles, createStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import MenuItem from "@material-ui/core/MenuItem";
import { translate } from "ra-core";
import get from "lodash/get";
import Specs from "./BaseInput.specs";
import { TYPE_TEXTAREA, TYPE_SELECT } from "./Types";

const styles = createStyles({
  root: {
    minWidth: 250
  },
  clearIcon: {
    height: 16,
    width: 0
  },
  visibleClearIcon: {
    width: 16
  },
  clearButton: {
    height: 24,
    width: 0
  },
  visibleClearButton: {
    width: 24
  }
});

/**
 * An override of the default Material-UI TextField which is resettable
 */
class TextInput extends Component {
  state = { showClear: false };

  handleClickClearButton = event => {
    event.preventDefault();
    this.props.onChange("");
  };

  handleMouseDownClearButton = event => {
    event.preventDefault();
  };

  handleFocus = event => {
    this.setState({ showClear: true });
    this.props.onFocus && this.props.onFocus(event);
  };

  handleChange = event => {
    this.props.onChange && this.props.onChange(event.target.value);
  };

  handleBlur = event => {
    this.setState({ showClear: false });
    this.props.onBlur && this.props.onBlur(event);
  };

  addAllowEmpty = choices => {
    if (this.props.allowEmpty) {
      return [
        <MenuItem value={this.props.emptyValue} key="null" />,
        ...choices
      ];
    }

    return choices;
  };

  renderMenuItem = (choice, idx) => {
    return (
      <MenuItem key={idx} value={get(choice, "value")}>
        {this.renderMenuItemOption(choice)}
      </MenuItem>
    );
  };

  renderMenuItemOption = choice => {
    return get(choice, "value");
  };

  render() {
    const {
      translate,
      classes,
      resettable,
      disabled,
      error,
      prop,
      ...props
    } = this.props;
    const { showClear } = this.state;
    const {
      clearButton,
      clearIcon,
      visibleClearButton,
      visibleClearIcon,
      ...restClasses
    } = classes;
    // Get prop details.
    const { name, value, type, config, label } = prop;
    return (
      <MuiTextField
        classes={restClasses}
        value={value}
        select={type === TYPE_SELECT}
        type={type}
        label={label || name}
        error={!!error}
        helperText={error}
        margin="normal"
        multiline={type === TYPE_TEXTAREA}
        InputProps={{
          endAdornment: resettable && value && (
            <InputAdornment position="end">
              <IconButton
                className={classNames(clearButton, {
                  [visibleClearButton]: showClear
                })}
                aria-label={translate("ra.action.clear_input_value")}
                title={translate("ra.action.clear_input_value")}
                disableRipple
                onClick={this.handleClickClearButton}
                onMouseDown={this.handleMouseDownClearButton}
                disabled={disabled}
              >
                <ClearIcon
                  className={classNames(clearIcon, {
                    [visibleClearIcon]: showClear
                  })}
                />
              </IconButton>
            </InputAdornment>
          )
        }}
        InputLabelProps={{
          shrink: true
        }}
        disabled={disabled}
        {...props}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
      >
        {config && this.addAllowEmpty(config.map(this.renderMenuItem))}
      </MuiTextField>
    );
  }
}
TextInput.propTypes = { ...Specs };
TextInput.defaultProps = {
  resettable: false
};

export default compose(translate, withStyles(styles))(TextInput);
