import React, { Component } from "react";
import { UserMenu, MenuItemLink, translate } from "react-admin";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";

class MyUserMenu extends Component {
  render() {
    const { translate, ...props } = this.props;
    return (
      <UserMenu {...props}>
        <MenuItemLink
          to={`/user-profile/me`}
          primaryText={translate("menu.items.profile")}
          leftIcon={<PersonIcon />}
        />

        <MenuItemLink
          to={`/user-password-change/create`}
          primaryText={translate("menu.items.password-change")}
          leftIcon={<LockIcon />}
        />
      </UserMenu>
    );
  }
}

export default translate(MyUserMenu);
