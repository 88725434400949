import { API_URL } from "./config";
import getHeaders from "./authHeaders";

export const EXEC_QUERY = "EXEC_QUERY";
export const MAP_COLUMNS = "MAP_COLUMNS";

const addQueriesFeature = (requestHandler) => (type, resource, params) => {
  if (type === EXEC_QUERY) {
    const url = `${API_URL}/queries/exec`;
    return fetch(url, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then(({ data }) => ({ data }));
  } else if (type === MAP_COLUMNS) {
    const url = `${API_URL}/queries/map-columns`;
    return fetch(url, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then(({ data }) => ({ data }));
  }
  return requestHandler(type, resource, params);
};
export default addQueriesFeature;
