import { API_URL } from "./config";
import { fetchUtils } from "react-admin";
import getHeaders from "./authHeaders";

const addLoginImageFeature = (requestHandler) => (type, resource, params) => {
  const { fetchJson } = fetchUtils;
  if (resource === "login-images" && type === "GET_RANDOM_IMAGE") {
    const url = `${API_URL}/${resource}/random`;
    return fetchJson(url, {
      method: "GET",
      headers: getHeaders(),
    }).then((response) => ({
      data: response.json.login_image,
    }));
  }
  return requestHandler(type, resource, params);
};

export default addLoginImageFeature;
