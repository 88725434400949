import React from "react";
import { List, Datagrid, TextField, EditButton, DateField } from "react-admin";
import Filters from "./Filters";
import BulkActions from "../components/list/BulkActions";
const UserList = props => (
  <React.Fragment>
    <List
      {...props}
      perPage={10}
      filters={<Filters />}
      bulkActionButtons={<BulkActions />}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <DateField source="created" showTime />
        <DateField source="modified" showTime />
        <EditButton />
      </Datagrid>
    </List>
  </React.Fragment>
);
export default UserList;
