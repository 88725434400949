import React from "react";
import { Filter } from "react-admin";
import FilterInput from "../inputs/FilterInput";
import expressions from "../columns/expressions";

const Filters = ({ query, ...props }) => (
  <Filter {...props}>
    {query.filters.length > 0 &&
      query.filters.map(filter => (
        <FilterInput
          key={filter.id}
          query={query}
          filter={filter}
          source={filter.qs_param}
          label={filter.alias}
          alwaysOn={filter.is_alwayson}
        />
      ))}
    {query.filters.length === 0 &&
      query.columns.map(column => (
        <FilterInput
          key={column.id}
          query={query}
          filter={{
            ...column,
            column: column.name,
            qs_param: `qsp_${column.name}`,
            expression: expressions.CHOSEN_BY_USER,
            is_alwayson: false
          }}
          source={`qsp_${column.name}`}
          label={column.alias}
        />
      ))}
  </Filter>
);
export default Filters;
