/**
 * List of supported prop types.
 */
export const TYPE_TEXT = "text";
export const TYPE_TEXTAREA = "textarea";
export const TYPE_NUMBER = "number";
export const TYPE_DATE = "date";
export const TYPE_DATETIME = "datetime-local";
export const TYPE_SELECT = "select";
export const TYPE_SELECT_MULTI = "select-multi";
export const TYPE_CHECKBOX = "checkbox";

export default [
  TYPE_TEXT,
  TYPE_TEXTAREA,
  TYPE_NUMBER,
  TYPE_DATE,
  TYPE_DATETIME,
  TYPE_SELECT,
  TYPE_SELECT_MULTI,
  TYPE_CHECKBOX
];
