import React from "react";
import PropTypes from "prop-types";
import {
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
} from "react-admin";
import { FormDataConsumer } from "ra-core";

const style = {
  display: "inline",
  float: "left",
  width: "auto",
  minWidth: 200,
};
const styleBrLeft = {
  ...style,
  clear: "right",
};
const styleInput = {
  display: "block",
  clear: "both",
  width: "100%",
};
const styleReadonly = {
  opacity: 0.5,
};

const PermsInput = ({
  mode = "users",
  disableRemove,
  formData,
  objectType,
  ...props
}) => (
  <ArrayInput {...props} style={{ width: "100%" }}>
    <SimpleFormIterator
      disableRemove={disableRemove}
      disableAdd={mode === "containers"}
    >
      <FormDataConsumer>
        {({ scopedFormData, getSource }) => (
          <div
            style={
              scopedFormData && scopedFormData.is_dummy === true
                ? styleReadonly
                : {}
            }
          >
            {mode === "users" && (
              <ReferenceInput
                label="resources.perms.fields.container"
                source={getSource("container_id")}
                reference="containers"
                resource="containers"
                defaultValue={0}
                perPage={100}
                sort={{ field: "tree_name", order: "ASC" }}
                filterToQuery={(searchText) => ({ tree_name: searchText })}
                filter={{
                  _ids:
                    formData && formData.perms && formData
                      ? formData.perms
                          .map((p) => p.container_id)
                          .filter((id) => id > 0)
                      : [],
                }}
              >
                <AutocompleteInput
                  options={{ fullWidth: true }}
                  stype={styleInput}
                  optionText="tree_name"
                />
              </ReferenceInput>
            )}
            {mode === "containers" && (
              <ReferenceInput
                label="resources.perms.fields.user"
                source={getSource("user_id")}
                reference="users"
                resource="users"
                defaultValue={0}
                perPage={10}
              >
                <AutocompleteInput
                  style={styleInput}
                  optionText="username"
                  options={{ fullWidth: true }}
                />
              </ReferenceInput>
            )}

            <BooleanInput
              label="resources.perms.fields.can_read"
              source={getSource("can_read")}
              style={style}
              defaultValue={true}
            />

            <BooleanInput
              label="resources.perms.fields.can_read_logs"
              source={getSource("can_read_logs")}
              style={style}
              defaultValue={true}
            />

            <BooleanInput
              label="resources.perms.fields.can_edit"
              source={getSource("can_edit")}
              style={style}
            />

            <BooleanInput
              label="resources.perms.fields.can_delete"
              source={getSource("can_delete")}
              style={style}
            />
            <BooleanInput
              label="resources.perms.fields.can_create"
              source={getSource("can_create")}
              style={styleBrLeft}
            />

            <BooleanInput
              label="resources.perms.fields.can_download"
              source={getSource("can_download")}
              style={style}
            />

            <BooleanInput
              label="resources.perms.fields.can_create_assets"
              source={getSource("can_create_assets")}
              style={style}
            />
            <BooleanInput
              label="resources.perms.fields.can_edit_assets"
              source={getSource("can_edit_assets")}
              style={style}
            />
            <BooleanInput
              label="resources.perms.fields.can_delete_assets"
              source={getSource("can_delete_assets")}
              style={style}
            />

            <BooleanInput
              label="resources.perms.fields.can_edit_perms"
              source={getSource("can_edit_perms")}
              style={style}
            />
            <BooleanInput
              label="resources.perms.fields.propagate"
              source={getSource("propagate")}
              style={style}
            />
          </div>
        )}
      </FormDataConsumer>
    </SimpleFormIterator>
  </ArrayInput>
);

PermsInput.propTypes = {
  canRemove: PropTypes.bool,
};

export default PermsInput;
