import { API_URL } from "./config";
import { fetchUtils } from "react-admin";
import getHeaders from "./authHeaders";

export const GET_STATS = "stats";

const addDashboardFeature = (requestHandler) => (type, resource, params) => {
  const { fetchJson } = fetchUtils;
  if (resource === "dashboard" && type === GET_STATS) {
    const url = `${API_URL}/dashboard/stats`;
    return fetchJson(url, {
      method: "GET",
      headers: getHeaders(),
    }).then((response) => ({
      data: response.json,
    }));
  }
  return requestHandler(type, resource, params);
};

export default addDashboardFeature;
