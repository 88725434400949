import React, { Fragment } from "react";
import { Labeled, TextField, SimpleForm, DateField } from "react-admin";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import CloseIcon from "@material-ui/icons/Close";
import ViewToolbar from "./ViewToolbar";
import filesize from "../utils/filesize";
import { Scrollbars } from "react-custom-scrollbars";
const styles = theme => ({
  root: {
    paddingTop: 40
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 1
  },
  form: {
    [theme.breakpoints.up("xs")]: {
      width: 400,
      overflow: "hidden"
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginTop: -30
    }
  },

  labeledField: {
    marginRight: theme.spacing.unit * 2
  },
  inlineField: {
    display: "inline-block",
    width: "50%",
    paddingRight: theme.spacing.unit * 1
  }
});

const PropsField = ({ record, classes }) => (
  <Fragment>
    {record.props.map(prop => (
      <Labeled key={prop.id} label={prop.name} className={classes.labeledField}>
        <TextField
          source="value"
          record={prop}
          formClassName={classes.inlineField}
        />
      </Labeled>
    ))}
  </Fragment>
);

const View = ({ record, classes, onCancel }) => (
  <Scrollbars
    autoHeight
    autoHeightMax={window.outerHeight - 180}
    autoHide={true}
  >
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="subheading">{record.name}</Typography>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <SimpleForm
        className={classes.form}
        basePath={"explorer"}
        record={{ ...record, size: filesize(record.size) }}
        toolbar={<ViewToolbar />}
        version={null}
        resource="explorer"
      >
        <DateField
          source="modified"
          label="resources.explorer.fields.modified"
          showTime
          formClassName={classes.inlineField}
        />

        {record.type === "container" && (
          <Labeled label="resources.explorer.fields.size">
            <TextField source="size" formClassName={classes.inlineField} />
          </Labeled>
        )}
        <PropsField classes={classes} />
        <TextField source="notes" />
      </SimpleForm>
    </div>
  </Scrollbars>
);

export default withStyles(styles)(View);
