import React, { Component } from "react";
import { connect } from "react-redux";
import { SaveButton } from "react-admin";
import { change } from "../actions/change";

class CustomSaveButton extends Component {
  handleClick() {
    const { basePath, handleSubmit, change } = this.props;

    return handleSubmit(values => {
      change(values, basePath);
    });
  }

  render() {
    return (
      <SaveButton handleSubmitWithRedirect={this.handleClick.bind(this)} />
    );
  }
}

export default connect(null, { change })(CustomSaveButton);
