import React, { Component } from "react";
import { Title, WithPermissions, withDataProvider } from "react-admin";

import FileDownloadIcon from "@material-ui/icons/FileDownload";
import FolderIcon from "@material-ui/icons/Folder";
import { GET_STATS } from "../addDashboardFeature";
import { Grid } from "@material-ui/core";
import Indicator from "./Indicator";
import StorageIcon from "@material-ui/icons/Storage";
import Welcome from "./Welcome";
import compose from "recompose/compose";
import { connect } from "react-redux";
import filesize from "../utils/filesize";
import { getGroups } from "../useGroups";
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  container: {
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing.unit * 1,
      marginTop: theme.spacing.unit * 4,
    },
  },
  indicator: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing.unit * 1,
    },
  },
});

class Dashboard extends Component {
  state = {
    stats: {
      used_space: 0,
      containers: 0,
      assets: 0,
    },
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // handle refresh
    if (this.props.version !== prevProps.version) {
      this.fetchData();
    }
  }

  async fetchData() {
    const { dataProvider } = this.props;
    const response = await dataProvider(GET_STATS, "dashboard", {});
    this.setState({
      stats: response.data,
    });
  }

  render() {
    const { stats } = this.state;
    const { permissions, classes } = this.props;
    const groups = getGroups();
    const needsHelp =
      (!groups || groups.length === 0) &&
      (!permissions || !permissions("admin"));
    if (needsHelp) {
      return <Welcome permissions={permissions} />;
    }

    return (
      <div className={classes.container}>
        <Title title={"Dashboard"} />
        <Grid container spacing={16}>
          <Grid
            item
            lg={4}
            md={6}
            sm={12}
            xs={12}
            className={classes.indicator}
          >
            <Indicator
              color="#f44336"
              label="dashboard.used_space"
              value={filesize(stats.used_space)}
              icon={StorageIcon}
            />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            sm={12}
            xs={12}
            className={classes.indicator}
          >
            <Indicator
              color="#ffd54f"
              label="dashboard.containers"
              value={stats.containers}
              icon={FolderIcon}
            />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            sm={12}
            xs={12}
            className={classes.indicator}
          >
            <Indicator
              color="#607d8b"
              label="dashboard.assets"
              value={stats.assets}
              icon={FileDownloadIcon}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  version: state.admin.ui.viewVersion,
});

const ComposedDashboard = compose(
  connect(mapStateToProps),
  withStyles(styles),
  withDataProvider
)(Dashboard);
const DashboardWithPermissions = () => (
  <WithPermissions
    render={({ permissions }) => (
      <ComposedDashboard permissions={permissions} />
    )}
  />
);
export default DashboardWithPermissions;
