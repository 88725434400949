import React from "react";
import { List, Datagrid, TextField, DateField, ChipField } from "react-admin";
import Filters from "./Filters";
const ArchiveLogList = props => (
  <List {...props} perPage={25} filters={<Filters />}>
    <Datagrid>
      <TextField source="id" />
      <ChipField source="type" />
      <DateField source="created" showTime />
      <TextField source="message" />
    </Datagrid>
  </List>
);

export default ArchiveLogList;
