import { CardActions, RefreshButton } from "react-admin";

import CreateButton from "./buttons/CreateButton";
import ImportButton from "./buttons/ImportButton";
import React from "react";

const Actions = ({
  bulkActions,
  basePath,
  displayedFilters,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  permissions,
  ...props
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    <RefreshButton />
    {permissions.can_create && (
      <CreateButton
        resource="containers"
        label="resources.explorer.actions.add_container"
        filterValues={filterValues}
        currentSort={props.currentSort}
        page={props.page}
        perPage={props.perPage}
      />
    )}
    {permissions.can_create_assets && (
      <CreateButton
        resource="assets"
        label="resources.explorer.actions.add_asset"
        filterValues={filterValues}
        currentSort={props.currentSort}
        page={props.page}
        perPage={props.perPage}
      />
    )}
    {permissions.can_create && permissions.can_create_assets && (
      <ImportButton
        resource="explorer"
        label="resources.explorer.actions.import"
        filterValues={filterValues}
      />
    )}
  </CardActions>
);

export default Actions;
